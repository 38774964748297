<div
    class="rubick px-5 sm:px-8 py-5 before:content-[''] before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 dark:before:from-darkmode-800 dark:before:to-darkmode-800 before:fixed before:inset-0 before:z-[-1]">
    <!-- BEGIN: Mobile Menu -->    
    <app-siderbar-mobile></app-siderbar-mobile>
    <!-- END: Mobile Menu -->
    <div class="mt-[4.7rem] flex md:mt-0">
      <!-- BEGIN: Side Menu -->
        <app-siderbar></app-siderbar>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div
        class="md:max-w-auto min-h-screen min-w-0 max-w-full flex-1 rounded-[30px] bg-slate-100 px-4 pb-10 before:block before:h-px before:w-full before:content-[''] dark:bg-darkmode-700 md:px-[22px]">
        <!-- BEGIN: Top Bar -->
        <app-top-bar></app-top-bar>
        <!-- END: Top Bar -->        
        <router-outlet></router-outlet>
      </div>
      <!-- END: Content -->
    </div>
  </div>