import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};


@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  baseUrl = '';//environment.url + 'api/log';

  constructor(private http: HttpClient,              
              private notify: ToastrService
  ) {

  }

  log(message: string): void {
    this.notifica(message, 'info');
  }

  trace(message: string): void {
    // if (!environment.production) {
    this.notifica(message, 'info');
    // }
  }

  error(message: string, tiempo?: number): void {
    if (tiempo === undefined) {
      tiempo = 5000;
    }
    // notify(message, 'error', tiempo);
    this.notify.error(message, 'Error', {timeOut: tiempo});
    const timeString: string = new Date().toLocaleTimeString();
    console.error(`ERROR: ${message} (${timeString})`);
  }

  // The message's type: "info", "warning", "error" or "success".
  notifica(mensaje: string, tipo: string, tiempo?: number, modulo?: string, titulo?: string) {
    try {
      if (tiempo === undefined) {
        tiempo = 3500;
      }
      // notify(mensaje, tipo, tiempo);
      switch (tipo) {
        case 'info':
          this.notify.info(mensaje, 'Información', {timeOut: tiempo});
          break;
        case 'warning':
          this.notify.warning(mensaje, 'Advertencia', {timeOut: tiempo});
          break;
        case 'error':
          this.notify.error(mensaje, 'Error', {timeOut: tiempo});
          break;
        case 'success':
          this.notify.success(mensaje, 'OK', {timeOut: tiempo});
          break;
      }
      const timeString: string = new Date().toLocaleTimeString();
      console.log(`${mensaje.toString()} (${timeString})`);
    } catch (e) {
      console.log('error en notifica');
    } 
  }

  private handleError<T>(operation = 'operation', result?: T | null) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      //this.logger.trace(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  errorHandler(component: string, method: string, message: string, user: string, args: string = '') {
    this.error(component + ': ' + method + ' -> ' + message + (args !== '' ? ' => ' + args : ''));
  }

}
