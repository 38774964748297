 <!-- BEGIN: Side Menu -->
 <nav class="side-nav hidden w-[80px] overflow-x-hidden pb-16 pr-5 md:block xl:w-[230px]">
    <a class="flex items-center pt-4 pl-5 intro-x" href="javascript:;">
        <img  src="./dist/images/ingenius-white.svg" alt="Ingenius - Mensajería WhatsApp">        
    </a>
    <div class="my-6 side-nav__divider"></div>
    <ul>
        <li>            
            <a routerLink="/authenticated/dashboard" (click)="AsignarTituloMenu('dashboard','')" [ngClass]="tituloMenu == 'dashboard' ? 'side-menu side-menu--active' : 'side-menu'">
                <div class="side-menu__icon">
                    <i data-tw-merge="" data-lucide="home" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="side-menu__title">
                    Dashboard
                </div>
            </a>
        </li>
        <li>
            <a routerLink="/authenticated/escanear-qr" (click)="AsignarTituloMenu('escanear-qr','')" [ngClass]="tituloMenu == 'escanear-qr' ? 'side-menu side-menu--active' : 'side-menu'">
                <div class="side-menu__icon">
                    <i data-tw-merge="" data-lucide="qr-code" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="side-menu__title">
                    Escanear QR
                </div>
            </a>
        </li>
        <li>
            <a routerLink="/authenticated/notificaciones" (click)="AsignarTituloMenu('notificaciones','')" [ngClass]="tituloMenu == 'notificaciones' ? 'side-menu side-menu--active' : 'side-menu'">
                <div class="side-menu__icon">
                    <i data-tw-merge="" data-lucide="bell" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="side-menu__title">
                    Notificaciones
                </div>
            </a>
        </li>
        <li>
            <a routerLink="/authenticated/chat-whatsapp" (click)="AsignarTituloMenu('chat-whatsapp','')" [ngClass]="tituloMenu == 'chat-whatsapp' ? 'side-menu side-menu--active' : 'side-menu'">
                <div class="side-menu__icon">
                    <i data-tw-merge="" data-lucide="send" class="stroke-1.5 w-5 h-5"></i>
                </div>
                <div class="side-menu__title">
                    Enviar mensajes
                </div>
            </a>
        </li>
    </ul>
</nav>
<!-- END: Side Menu -->