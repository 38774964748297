import { NgModule } from '@angular/core';
import { DxSwitchModule } from 'devextreme-angular';

@NgModule({
  imports:[
    DxSwitchModule
      
  ],
  exports:[
    DxSwitchModule
  ],
})

export class DevexpressModule{

}
