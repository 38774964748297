<!-- BEGIN: Basic Card -->
<div class="preview-component intro-y box">
    <div class="intro-y mt-2 flex flex-col items-center sm:flex-row">
        <div class="flex mr-2 w-full sm:mt-0 sm:w-auto">
          <button data-tw-merge="" data-placement="top" title="Regresar" [class]="styleListSmallButtonRoundedSecondary"
            class="rounded-full tooltip" (click)="Atras()"><i data-tw-merge="" data-lucide="arrow-left"
              [class]="styleIconButtonSmall"></i></button>
        </div>    
        <h2 class="mr-auto text-lg font-medium">ESCANEAR QR</h2>  
      </div>
  <div class="p-5 flex justify-center">
    <!-- BEGIN: Data List -->    
    <img [src]="stringQR" class="image width-50" />
    <!-- END: Data List -->
  </div>
</div>
<!-- END: Basic Card -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Espere un momento por favor... </p>
</ngx-spinner>
