import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from "@angular/router";
import { AuthenticationService } from './authentication.service';
import { SettingsService } from '../auth/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(public settings: SettingsService, public authenticationService: AuthenticationService
              ) {
  }

  canActivate(): boolean {
    if(this.settings.isAuthenticated() && this.authenticationService.LogIn()){
      return true;
    }
    else{
      console.log('Token expirado: ', this.authenticationService.LogIn());
      this.authenticationService.LogOut();      
      return false;
    }
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
