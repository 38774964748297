import { Component, OnInit } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../environments/environment';
import { WhatsAppService } from '../services/whatsapp.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EstilosService } from '../services/estilos.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CargarScriptsService } from '../services/cargar-scripts.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

const urlHubQR = environment.apiSignalBase + 'hub-qr';
const urlHubChat = environment.apiSignalBase + 'hub-chat';
@Component({
  selector: 'app-recepcionar-usuario',
  templateUrl: './recepcionar-usuario.component.html',
  styleUrls: ['./recepcionar-usuario.component.scss']
})
export class RecepcionarUsuarioComponent implements OnInit {
  private hubConnectionQR: signalR.HubConnection;
  private hubConnectionChat: signalR.HubConnection;
  conectionIdQR: string | null | undefined = '';
  conectionIdChat: string | null | undefined = '';
  timerSignalrQr!: NodeJS.Timer;
  timerSignalrChat!: NodeJS.Timer;
  stringQR:string='';
  styleDivMainModal: String = '';
  styleAX: String = '';
  styleIconX: String = '';
  styleDivHeaderModal: String = '';
  styleIconButtonSmall: String = '';
  styleListSmallButtonRoundedSecondary: String = '';
  
  constructor(
    private whatsAppService: WhatsAppService, 
    private spinner: NgxSpinnerService,
    private estilosService: EstilosService,    
    private bsModalRef: BsModalRef,
    public route: Router,   
    private cargarScriptsService: CargarScriptsService
  ) { 
    this.styleDivMainModal = this.estilosService.StyleDivMainModal();
    this.styleAX = this.estilosService.StyleAX();
    this.styleIconX = this.estilosService.StyleIconX();
    this.styleDivHeaderModal = this.estilosService.StyleDivHeaderModal();
    this.styleIconButtonSmall = this.estilosService.StyleIconButtonSmall();
    this.styleListSmallButtonRoundedSecondary = this.estilosService.StyleListSmallButtonRoundedSecondary();
    //this.CargarComponent();
    //signalR
    this.hubConnectionQR = new signalR.HubConnectionBuilder()
      .withUrl(urlHubQR)
      .withAutomaticReconnect([0, 1000, 5000, 10000])
      .configureLogging(signalR.LogLevel.Information)
      .build();
    this.hubConnectionChat = new signalR.HubConnectionBuilder()
      .withUrl(urlHubChat)
      .withAutomaticReconnect([0, 1000, 5000, 10000])
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.iniciarBucleConexionSignalQR();
    this.iniciarBucleConexionSignalChat();

    this.hubConnectionQR
      .start()
      .then(() => {
        console.log('Connection qr started');
        let conectionId = this.hubConnectionQR?.connectionId;

        //unirse a grupo
        this.hubConnectionQR?.invoke('AgregarGrupo', conectionId, "grupo-recepcion-qr");

        ////Eliminar estos eventos
        this.hubConnectionQR.on('QR_RecepcionQr', (data: any) => {
          const received = `Recibido - QR_RecepcionQr:`;
          console.log(received, data);          
           let qr = JSON.parse(data.payload);
           this.stringQR = 'data:image/jpeg;base64,'+qr.QrCodeBase64;
        });

        this.hubConnectionQR.on('Grupo_RecepcionUnirse', (data: any) => {
          const received = `Recibido - Grupo_RecepcionUnirse: ${data}`;
          console.log(received);
        });
      })
      .catch(err => console.log('Error while starting connection: ' + err));
    this.hubConnectionQR.onclose(() => {
      console.log('Desconectado Notificaciones QR: ');
      this.iniciarBucleConexionSignalQR();
    });
 
    this.hubConnectionChat
      .start()
      .then(() => {
        console.log('Connection sr started');
        let conectionId = this.hubConnectionChat?.connectionId;

        //unirse a grupo
        this.hubConnectionChat?.invoke('AgregarGrupo', conectionId, "chat-nuevo-mensaje-general");

        ////Eliminar estos eventos
        this.hubConnectionChat.on('Chat_RecepcionNuevoMensajeGeneral', (data: any) => {
          const received = `Recibido - Chat_RecepcionNuevoMensajeGeneral:`;
          console.log(received, data);
        });

        this.hubConnectionChat.on('Grupo_RecepcionUnirse', (data: any) => {
          const received = `Recibido - Grupo_RecepcionUnirse: ${data}`;
          console.log(received);
        });
      })
      .catch(err => console.log('Error while starting connection: ' + err));
    this.hubConnectionChat.onclose(() => {
      console.log('Desconectado Notificaciones CHAT: ');
      this.iniciarBucleConexionSignalChat();
    });

  }

  ngOnInit(): void {
    this.ObtenerIniciarCliente();
    this.EjecutarScript();
  }

  ngOnDestroy(): void {
    // clearInterval(this.timerSignalr);
  }

  
  /**
   * Inicia un bucle para la reconexion de signal
   * Autor: Ansoli Espinoza
   */
  iniciarBucleConexionSignalQR() {
    var refToThis = this;
    // this.timerSignalrQr = setInterval(function () {
    //   refToThis.startHubQR();
    // }, 10000);
  }
  /**
  * Inicia un bucle para la reconexion de signal
  * Autor: Ansoli Espinoza
  */
  iniciarBucleConexionSignalChat() {
    var refToThis = this;
    // this.timerSignalrChat = setInterval(function () {
    //   refToThis.startHubCHAT();
    // }, 10000);
  }

  /**
   * Inicia la conexion con el Hub de signal
   * Autor: Ansoli Espinoza
   */
  startHubQR() {
    console.log('Estableciendo conexion al Hub QR ...');

    this.hubConnectionQR.start().then(con => {
      // this.settings.isAuthenticated();
      // this.usuarioSignal = this.settings.getUserSetting('usuario');

      console.log('Eliminando BucleConexion QR');
      // clearInterval(this.timerSignalr);

      this.conectionIdQR = this.hubConnectionQR?.connectionId;
      console.log('conectionIdQR', this.hubConnectionQR?.connectionId);

      //unirme a grupo
      this.hubConnectionQR?.invoke('AgregarGrupo', this.conectionIdQR, 'chat-nuevo-mensaje');

      this.SuscribirseEventosQR();

    }).catch(err => console.error('Error al conectar al Hub QR:', err.toString()));
  }
  /**
 * Inicia la conexion con el Hub de signal
 * Autor: Ansoli Espinoza
 */
  startHubChat() {
    console.log('Estableciendo conexion al Hub CHAT...');

    this.hubConnectionChat.start().then(con => {
      // this.settings.isAuthenticated();
      // this.usuarioSignal = this.settings.getUserSetting('usuario');

      console.log('Eliminando BucleConexion CHAT');
      // clearInterval(this.timerSignalr);

      this.conectionIdChat = this.hubConnectionChat?.connectionId;
      console.log('conectionIdChat', this.hubConnectionChat?.connectionId);

      //unirme a grupo
      this.hubConnectionChat?.invoke('AgregarGrupo', this.conectionIdChat, 'chat-nuevo-mensaje');

      this.SuscribirseEventosChat();

    }).catch(err => console.error('Error al conectar al Hub:', err.toString()));
  }

  /**
* Suscripcion a los eventos de Signal
* Autor: Ansoli Espinoza
*/
  SuscribirseEventosQR() {
    this.hubConnectionQR.on('QR_RecepcionQr', (data: any) => {
      const received = `Recibido - QR_RecepcionQr:`;
      console.log(received, data);
      console.log(JSON.parse(data)); 
    });

    this.hubConnectionQR.on('Grupo_RecepcionUnirse', (data: any) => {
      const received = `Recibido - Grupo_RecepcionUnirse: ${data}`;
      console.log(received);
    });
  }
  /**
* Suscripcion a los eventos de Signal
* Autor: Ansoli Espinoza
*/
  SuscribirseEventosChat() {
    this.hubConnectionChat.on('Chat_RecepcionNuevoMensajeGeneral', (data: any) => {
      const received = `Recibido - Chat_RecepcionNuevoMensajeGeneral:`;
      console.log(received, data);
    });

    this.hubConnectionChat.on('Grupo_RecepcionUnirse', (data: any) => {
      const received = `Recibido - Grupo_RecepcionUnirse: ${data}`;
      console.log(received);
    });
  }  
/*
  CargarComponent() {
    if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload')
    location.reload()
    } else {
      localStorage.removeItem('foo')
    }
  }
*/
  ObtenerIniciarCliente() {
    /*this.spinner.show().then();
    this.whatsAppService.ObtenerIniciarCliente().subscribe(data=>{
     console.log(data)      
    });*/

    this.spinner.show().then();
    this.whatsAppService.ObtenerIniciarCliente()
      .subscribe({
        next: (data) => {
          console.log(data);   
          if(data.estado){
            this.MostrarNotificacionInfo('',data.respuesta);
          }else{
            this.MostrarNotificacionError('Hubo un error al generar el QR',data.respuesta);
          }
        },
        error: (e) => {
          console.log(e);
          this.spinner.hide().then();
        },
        complete: () => { this.spinner.hide().then(); }
      });
   }
  
   EjecutarScript() {
    this.cargarScriptsService.CargarArchivoJS('components/base/lucide.js', 'id-base-lucide');
    this.cargarScriptsService.CargarArchivoJS('vendors/transition.js', 'id-transition');
    this.cargarScriptsService.CargarArchivoJS('pages/chat.js', 'id-chat');
    this.cargarScriptsService.CargarArchivoJS('components/base/tippy.js', 'id-base-tippy');
  }

 /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 12/04/2024
  /// Versión: 1.0
  /// <summary>
  /// Permite cerrar el modal.
  /// </summary>
  CerrarModal() {
    this.bsModalRef.hide();
  }

  Atras() {
    this.route.navigate(['authenticated/dashboard']).then();
  }

    /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  /// Funciones para mostrar notificaciones.
  /// </summary>
  MostrarNotificacionSuccess(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'success',
      title: titulo,
      text: mensaje,
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
      }
    })
  }

  MostrarNotificacionEliminar(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: mensaje,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Confirmar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
      }
    })
  }

  MostrarNotificacionInfo(titulo: string, mensaje: string) {
    console.log('información');
    Swal.fire({
      icon: 'info',
      title: titulo,
      text: mensaje
    });
  }

  MostrarNotificacionWarning(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: mensaje
    });
  }

  MostrarNotificacionError(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      html: `<div class="message-text-error">${mensaje} </div>`,
    });
  }
}
