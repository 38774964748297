import { NgModule } from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatStepperModule} from '@angular/material/stepper';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  imports:[
      MatToolbarModule,
      MatButtonModule,
      MatCardModule,
      MatIconModule,
      MatInputModule,        
      MatTableModule,
      MatSidenavModule,
      MatDialogModule,
      MatMenuModule,
      MatTooltipModule,
      MatSnackBarModule,
      MatPaginatorModule,
      MatSelectModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatStepperModule,
      MatAutocompleteModule,
      MatRadioModule,
      MatSlideToggleModule,
      MatCheckboxModule
      
  ],
  exports:[
      MatToolbarModule,
      MatButtonModule,
      MatCardModule,
      MatIconModule,
      MatInputModule,
      MatTableModule,
      MatSidenavModule,
      MatDialogModule,
      MatMenuModule,
      MatTooltipModule,
      MatSnackBarModule,
      MatPaginatorModule,
      MatSelectModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatStepperModule,
      MatAutocompleteModule,
      MatRadioModule,
      MatSlideToggleModule,
      MatCheckboxModule
  ],
})

export class MaterialModule{

}
