import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggerService } from 'src/app/services/logger.service';
import { LoginService } from 'src/app/services/login.service';
import { LoginRequestDTO } from 'src/app/models/login-request';
import { AccesosLoginEnum } from 'src/app/enum/accesos-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  dataFormGroup: FormGroup;
  verSpinner:boolean=false;
  formError: string | null;
  submitting = false;
  isBusy = false;
  constructor( private router: Router, private settings: SettingsService, private loginService: LoginService,  private logger: LoggerService, private spinner: NgxSpinnerService) {
    this.dataFormGroup = new FormGroup({
      inputUsuario: new FormControl('', [Validators.required]),
      inputPassword: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.EliminarElementosPorClase('dropdown-menu');
  } 

  SubmitForm($ev: any, value: any) {
    console.log(value);
    
    $ev.preventDefault();
    for (let c in this.dataFormGroup.controls) {
      this.dataFormGroup.controls[c].markAsTouched();
    }
    if (this.dataFormGroup.valid) {
      this.spinner.show().then();
      let dataLogin = new LoginRequestDTO();
      dataLogin.Usuario = value.inputUsuario;
      dataLogin.Clave = value.inputPassword;  
      if(dataLogin.Usuario == AccesosLoginEnum.Usuario && dataLogin.Clave == AccesosLoginEnum.Clave){
        this.settings.setUserSetting('isAuthenticated', true);
        this.settings.setUserSetting('idUsuario', 0);
        this.settings.setUserSetting('idPersonal', 0);
        this.settings.setUserSetting('usuario', dataLogin.Usuario);
        this.settings.setUserSetting('nombres', 'Administrador');
        this.settings.setUserSetting('apellidos', 'Principal');
        this.settings.setUserSetting('idRol', 1);
        this.router.navigate(['/authenticated/dashboard']);
        this.submitting = false;
        this.spinner.hide().then();
      }
      else{
        this.submitting = false;
        this.logger.error('Usuario / Password no válidos, Intente nuevamente');
        this.spinner.hide().then();
      }
      /*                    
      this.loginService.LoginUsuarios(dataLogin)
        .subscribe({
          next: (data) => {
            if (data != null) {
              console.log(data);
              this.settings.setUserSetting('isAuthenticated', true);
              this.settings.setUserSetting('idUsuario', data.idUsuario);
              this.settings.setUserSetting('idPersonal', data.idPersonal);
              this.settings.setUserSetting('usuario', data.usuario);
              this.settings.setUserSetting('nombres', data.nombres);
              this.settings.setUserSetting('apellidos', data.apellidos);
              this.settings.setUserSetting('idRol', data.idRol);
              this.router.navigate(['/authenticated/dashboard']);
            }
            else {
              this.settings.setUserSetting('isAuthenticated', false);              
              this.settings.setUserSetting('idUsuario', '');
              this.settings.setUserSetting('idPersonal', '');
              this.settings.setUserSetting('usuario', '');
              this.settings.setUserSetting('nombres', '');
              this.settings.setUserSetting('apellidos','');
              this.settings.setUserSetting('idRol', '');
            }
            this.submitting = false;
          },
          error: (e) => {
            console.log(e);
            this.submitting = false;
            if(e.status=='400'){
              this.logger.error('Usuario / Password no válidos, Intente nuevamente');           
            }else{
              this.logger.error('Error en la verificación, Intente nuevamente');  
            }
            this.spinner.hide().then();
            
          },
          complete: () => { 
            this.spinner.hide().then();
           }
        });
      */
    }
  }
  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 11/04/2024
  /// Versión: 1.0
  /// <summary>
  ///  Permite resumir al control para el interfaz.
  /// </summary>
  get Controls() {
    return this.dataFormGroup.controls;
  }

  EliminarElementosPorClase(nameClass:string){        
    let body = document.getElementsByTagName('body')[0];
    let className = document.getElementsByClassName(nameClass);  
    console.log(className.length);
    for (let index = 0; index < className.length; index++) {      
      body.removeChild(className[index]);
      
    }        
}
}
