<div class="intro-y mt-8 flex flex-col items-center sm:flex-row">
  <h2 class="mr-auto text-lg font-medium">Chat</h2>
  <div class="col-span-12 mt-2 flex justify-between">


  </div>
  <div class="mt-4 flex w-full sm:mt-0 sm:w-auto">
    <button data-tw-merge="" data-placement="top" title="Regresar" [class]="styleListSmallButtonRoundedSecondary"
      class="rounded-full tooltip" (click)="Atras()"><i data-tw-merge="" data-lucide="arrow-left"
        [class]="styleIconButtonSmall"></i></button>
    <!--<button data-tw-merge="" data-placement="top" title="Filtrar por cliente"
      [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="EsCliente()"><i data-tw-merge=""
        data-lucide="user" [class]="styleIconButtonSmall"></i></button>
    <button data-tw-merge="" data-placement="top" title="Filtrar personal clínica"
      [class]="styleListSmallButtonRoundedPending" class="rounded-full" (click)="EsPersonalClinica()"><i
        data-tw-merge="" data-lucide="users" [class]="styleIconButtonSmall"></i></button>-->
    <button data-tw-merge="" data-placement="top" title="Listar todos" [class]="styleListSmallButtonRoundedDark"
      class="rounded-full" (click)="TodosListado()"><i data-tw-merge="" data-lucide="list"
        [class]="styleIconButtonSmall"></i></button>
    <button  data-tw-merge="" data-placement="top"
      title="Nuevo chat" [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="AbrirNuevoChat()"><i
        data-tw-merge="" data-lucide="plus" [class]="styleIconButtonSmall"></i></button>

  </div>
</div>
<div class="intro-y mt-5 grid grid-cols-12 gap-5">
  <!-- BEGIN: Chat Side Menu -->
  <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
    <div class="pr-1">
      <div class="box px-5 pb-5 pt-5">
        <div class="relative text-slate-500">
          <form class="form" autocomplete="off" novalidate="novalidate" [formGroup]="dataFormGroupBusqueda">
            <input data-tw-merge="" type="text" placeholder="Buscar mensajes" [class]="styleSearchChat"
              (keydown)="BuscarChat($event)" formControlName="inputBuscarChat">
            <i data-tw-merge="" data-lucide="search"
              class="stroke-1.5 inset-y-0 right-0 my-auto mr-4 h-4 w-4 sm:absolute"></i>
          </form>
        </div>
      </div>
    </div>
    <div class="chat-list scrollbar-hidden h-[660px] overflow-y-auto pr-1 pt-1">
      <ng-container *ngFor="let lista of listadoAsignacionConversacion">
        <div [ngClass]="lista.esPersonalClinica ? 'row-background' : ''"
          class="intro-x cursor-pointer box relative flex items-center p-5 mt-5" style="cursor: pointer;" (click)="ObtenerConversacionPorIdDestinatario(lista, true)">
          <div class="image-fit mr-1 h-12 w-12 flex-none">
            <img class="rounded-full" src="dist/images/fakers/no-avatar.jpg" alt="">
            <div
              class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600">
            </div>
          </div>
          <div class="ml-2 overflow-hidden">
            <div class="flex items-center">
              <a class="font-medium" href="javascript:;">
                {{lista.destinatario}}
              </a>
              <div class="ml-auto text-xs text-slate-400">
                {{lista.fechaUltimoMensaje | date:
                                      'yyyy-MM-dd hh:mm'}}
              </div>
            </div>
            <div class="mt-0.5 w-full truncate text-slate-500" *ngIf="lista.numeroCliente!=''; else EspaciosBlanco">
              {{lista.numeroCliente}}
            </div>
           <!-- <div class="mt-0.5 flex items-center justify-end">
              <button *ngIf="esAdministrador || esSupervisor || esEjecutivo" data-tw-merge="" data-placement="top"
                  title="Perfil contacto" [class]="styleListSmallButtonRoundedSecondary"
                  (click)="IrPerfil(lista.idAsignacionConversacion)"><i data-tw-merge="" data-lucide="user"
                    [class]="styleIconButtonSmall"></i></button>
                <button *ngIf="esAdministrador || esSupervisor || esEjecutivo" data-tw-merge="" data-placement="top"
                  title="Reasignar chat" [class]="styleListSmallButtonRoundedSecondary"
                  (click)="AbrirReasignarChat(lista.idAsignacionConversacion)"><i data-tw-merge=""
                    data-lucide="refresh-ccw" [class]="styleIconButtonSmall"></i></button>
              
            </div>-->
          </div>
          <div
            class="absolute right-0 top-0 -mr-1 -mt-1 flex h-5 w-5 items-center justify-center rounded-full bg-primary text-xs font-medium text-white"
            *ngIf="lista.contador!=0">
            {{lista.contador}}
          </div>
        </div>
      </ng-container>
      <ng-template #EspaciosBlanco >
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp;
      </ng-template>
    </div>
  </div>
  <!-- END: Chat Side Menu -->
  <!-- BEGIN: Chat Content -->
  <div class="intro-y col-span-12 lg:col-span-8 2xl:col-span-9">
    <div class="chat-box box h-[782px]">
      <!-- BEGIN: Chat Active -->
      <div id="body-chat" class="flex hidden h-full flex-col">
        <div class="flex flex-col border-b border-slate-200/60 px-5 py-4 dark:border-darkmode-400 sm:flex-row">
          <div class="flex items-center">
            <div class="image-fit relative h-10 w-10 flex-none sm:h-12 sm:w-12">
              <img class="rounded-full" src="dist/images/fakers/no-avatar.jpg" alt="">
            </div>
            <div class="ml-3 mr-auto">
              <div class="text-base font-medium">
                {{destinatarioElegido}}
              </div>
              <div class="text-xs text-slate-500 sm:text-sm">
                {{destinatarioFechaElegido | date: 'yyyy-MM-dd HH:mm:ss'}}
              </div>
            </div>
          </div>
        </div>
        <div class="scrollbar-hidden flex-1 overflow-y-scroll px-5 pt-5" #window [scrollTop]="window.scrollHeight" *ngIf="listadoMensajesDestinatario.length > 0">
          <ng-container *ngFor="let lista of listadoMensajesDestinatario">
            <div [class]="VerificarMensaje(lista.idMensajeTipo, lista)">
              <div *ngIf="!VerificarPosicionAvatar(lista.idMensajeTipo, lista)"
                class="image-fit relative mr-5 hidden h-10 w-10 flex-none sm:block">
                <img class="rounded-full" src="dist/images/fakers/no-avatar.jpg" alt="">
              </div>
              <div [class]="VerificarFondoMensaje(lista.idMensajeTipo, lista)">
                <div class="grid grid-cols-4 gap-5" *ngIf="lista.isDocument">
                  <div class="boton-download-file"
                    (click)="DescargarArchivo(lista.mimeType, lista.adjuntoBase64,lista.nombreArchivo)">
                    <div class="flex justify-center">
                      <img src="../../assets/text-document.svg" />
                    </div>

                  </div>
                  <div class="col-span-3">
                    <div class="grid grid-cols-1">
                      <div class="font-size-15">
                        {{lista.nombreArchivo}}</div>                      
                    </div>
                  </div>
                </div>
                <div *ngIf="lista.isImage">
                  <img [src]="DevolverImagen(lista.mimeType, lista.adjuntoBase64)" class="image width-100" />
                </div>
                <div *ngIf="lista.isAudio">
                  <audio controls>
                    <source [src]="DevolverAudio(lista.mimeType, lista.adjuntoBase64)">
                  </audio>
                </div>
                <div *ngIf="lista.isVideo">
                  <video controls class="width-100">
                    <source [type]="DevolverMimeType(lista.mimeType)"
                      [src]="DevolverVideo(lista.mimeType, lista.adjuntoBase64)">
                  </video>
                </div>

                <div *ngIf="!lista.isDocument">{{ lista.mensaje }}</div>

                <div [class]="VerificarFechaMensaje(lista.idMensajeTipo, lista)">
                  {{lista.fechaEnvio | date: 'yyyy-MM-dd HH:mm:ss'}}
                </div>
              </div>
              <div *ngIf="VerificarPosicionAvatar(lista.idMensajeTipo, lista)"
                class="image-fit relative ml-5 hidden h-10 w-10 flex-none sm:block">
                <img class="rounded-full" src="dist/images/fakers/no-avatar.jpg" alt="">
              </div>
            </div>
            <div class="clear-both"></div>


          </ng-container>
        </div>      
        <div class="flex items-center border-t border-slate-200/60 pb-10 pt-4 dark:border-darkmode-400 sm:py-4">
          <form class="w-full form" autocomplete="off" novalidate="novalidate" [formGroup]="dataFormGroup">
            <div class="p-5 grid grid-cols-7 gap-5">
              <div class="col-span-12 text-center">
                <button data-tw-merge="" data-placement="top" title="Enviar archivo"
                  [class]="styleListSmallButtonRoundedPrimary" class="rounded-full"
                  (click)="TriggerFile(fileInput,true,false,false,false)"><i data-tw-merge="" data-lucide="file-text"
                    [class]="styleIconButtonSmall"></i></button>
                &nbsp;&nbsp;

                <button data-tw-merge="" data-placement="top" title="Enviar imagen"
                  [class]="styleListSmallButtonRoundedPrimary" class="rounded-full"
                  (click)="TriggerFile(fileInput,false,true,false,false)"><i data-tw-merge="" data-lucide="image"
                    [class]="styleIconButtonSmall"></i></button>
                &nbsp;&nbsp;
                <button data-tw-merge="" data-placement="top" title="Enviar audio"
                  [class]="styleListSmallButtonRoundedPrimary" class="rounded-full"
                  (click)="TriggerFile(fileInput,false,false,true,false)"><i data-tw-merge="" data-lucide="music"
                    [class]="styleIconButtonSmall"></i></button>
                &nbsp;&nbsp;

                <button data-tw-merge="" data-placement="top" title="Enviar video"
                  [class]="styleListSmallButtonRoundedPrimary" class="rounded-full"
                  (click)="TriggerFile(fileInput,false,false,false,true)"><i data-tw-merge="" data-lucide="video"
                    [class]="styleIconButtonSmall"></i></button>              
              </div>
              <div class="col-span-11">
                <textarea autosize data-tw-merge="" rows="1" placeholder="Escriba su mensaje"
                  [class]="styleTextareaChat" formControlName="inputMensaje"
                  (keydown.control.enter)="EnviarMensaje()"></textarea>
                <input hidden (change)="VerificarArchivoBase64($event, MyFile)" #fileInput type="file"
                  formControlName="inputFile">
                <div
                  *ngIf="Controls['inputMensaje'].invalid && (Controls['inputMensaje'].dirty || Controls['inputMensaje'].touched)"
                  class="text-error">
                  <div>Campo obligatorio.</div>
                </div>
              </div>
              <div class="flex items-end">                
                <button data-tw-merge="" data-placement="top" title="Enviar mensaje"
                  [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="EnviarMensaje()"><i
                    data-tw-merge="" data-lucide="send" [class]="styleIconButtonSmall"></i></button>
                <!--<button data-tw-merge="" data-placement="top" title="Ver plantillas"
                  [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="AbrirModal()"><i
                    data-tw-merge="" data-lucide="layers" [class]="styleIconButtonSmall"></i></button>
                <button data-tw-merge="" data-placement="top" title="Ver botones"
                  [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="AbrirModalBotones()"><i
                    data-tw-merge="" data-lucide="box" [class]="styleIconButtonSmall"></i></button>-->
              </div>
            </div>
            <ng-template #MyFile>
              <div data-tw-merge="">
                <a [class]="styleAX" (click)="CerrarModal()" href="javascript:;">
                  <i data-tw-merge data-lucide="x" [class]="styleIconX"></i>
                </a>
                <div [class]="styleDivHeaderModal">
                  <h2 class="mr-auto text-base font-medium">
                    ARCHIVO ADJUNTO
                  </h2>
                </div>
                <div data-tw-merge="" class="p-8 grid grid-cols-12 gap-4 gap-y-3">
                  <div *ngIf="isApplication" class="col-span-12">
                    <div class="boton-download-file flex justify-center">
                      <img src="../../assets/text-document.svg" class="width-50" />
                    </div>
                  </div>
                  <div *ngIf="isImage" class="col-span-12">
                    <img [src]="archivoActual" class="image width-100" />
                  </div>
                  <div *ngIf="isAudio" class="col-span-12 flex justify-center">
                    <audio controls>
                      <source [src]="archivoActual">
                    </audio>
                  </div>
                  <div *ngIf="isVideo" class="col-span-12 flex justify-center">
                    <video controls class="width-50">
                      <source [src]="archivoActual">
                    </video>
                  </div>
                  <!--<div class="col-span-12 mb-3 mt-3">
                        <strong>El tamaño máximo del archivo es {{tamanioImage}}
                          MB.</strong>
                      </div>-->
                  <div class="col-span-11">
                    <textarea autosize data-tw-merge="" rows="1" placeholder="Escriba su mensaje"
                      [class]="styleTextareaChat" formControlName="inputMensaje"
                      (keydown.control.enter)="EnviarMensaje()"></textarea>
                  </div>
                  <div class="flex items-end">
                    <button data-tw-merge="" data-placement="top" title="Enviar mensaje"
                      [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="EnviarMensaje()"><i
                        data-tw-merge="" data-lucide="send" [class]="styleIconButtonSmall"></i></button>
                  </div>
                </div>
                <!--<div class="py-3 text-right border-t border-slate-200/60 dark:border-darkmode-400">
                      <button data-tw-merge (click)="CerrarModal()" data-placement="top" title="Cerrar ventana" [class]="styleButtonDanger"> <i data-tw-merge
                        data-lucide="x" [class]="styleIconButtonText"></i>
                        Cerrar</button>
                    </div>-->
              </div>
            </ng-template>

          </form>
        </div>
      </div>
      <!-- END: Chat Active -->
      <!-- BEGIN: Chat Default -->
      <div id="wait-chat" class="flex h-full items-center">
        <div class="mx-auto text-center">
          <div class="image-fit mx-auto h-16 w-16 flex-none overflow-hidden rounded-full">
            <img src="dist/images/fakers/no-avatar.jpg" alt="">
          </div>
          <div class="mt-3">
            <div class="font-medium">
              Mensajería
            </div>
            <div class="mt-1 text-slate-500">
              Por favor, seleccione un chat para empezar el un mensaje.
            </div>
          </div>
        </div>
      </div>
      <!-- END: Chat Default -->
    </div>
  </div>
  <!-- END: Chat Content -->
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Espere un momento por favor... </p>
</ngx-spinner>
