import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AuthGuard} from "./services/auth-guard.service";
import { LoginComponent } from './auth/login/login.component';
import { PagesComponent } from './pages/pages.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { RecepcionarUsuarioComponent } from './recepcionar-usuario/recepcionar-usuario.component';
import { ChatWhatsappComponent } from './chat-whatsapp/chat-whatsapp.component';


export const routes: Routes = [
  {path: 'login', component: LoginComponent}, 
  {
    //path: 'authenticated', component: PagesComponent, canActivate: [AuthGuard],    
    path: 'authenticated', component: PagesComponent,    
    children: [
      {
       //path: '', canActivateChild: [AuthGuard],      
       path: '',     
        children: [
          {path: 'dashboard', component: DashboardComponent},                              
          {path: 'notificaciones', component: NotificacionesComponent},
          {path: 'escanear-qr', component: RecepcionarUsuarioComponent},
          {path: 'chat-whatsapp', component: ChatWhatsappComponent},
        ]
      }
    ]
  },
  {path: '', component: LoginComponent},
  {path: '**', component: LoginComponent},

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
