import { Router } from "@angular/router";
import { TiposRolesEnum } from '../enum/tipos-roles';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from "../auth/settings/settings.service";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  
  esAdministrador: boolean = false;
  esSupervisor: boolean = false;
  esEjecutivo: boolean = false;

  constructor(
    private router: Router,      
    public settings: SettingsService,
    ) {}

  ngOnDestroy(): void {
  }

  ngOnInit(): void {    
    let idRol = this.settings.getUserSetting('idRol');
    if(idRol == TiposRolesEnum.Administrador) {
      this.esAdministrador = true;
      this.esSupervisor = false;
      this.esEjecutivo = false;
    }
    else if(idRol == TiposRolesEnum.Supervisor) {
      this.esAdministrador = false;
      this.esSupervisor = true;
      this.esEjecutivo = false;
    }
    else if(idRol == TiposRolesEnum.Ejecutivo) {
      this.esAdministrador = false;
      this.esSupervisor = false;
      this.esEjecutivo = true;
    }
    this.CargarComponent();
  }

  envioGeneracionQRClick() {
    this.router.navigate(['authenticated/escanear-qr']).then();
  }

  envioNotificacionesClick() {
    this.router.navigate(['authenticated/notificaciones']).then();
  }

  envioContenidoClick() {
    this.router.navigate(['authenticated/chat-whatsapp']).then();
  }

  CargarComponent() {
    if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload')
      location.reload()
    } else {
      localStorage.removeItem('foo')
    }
  }

}
