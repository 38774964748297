import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

//const API_URL_WHATSAPP_DOCUMENTATION = environment.urlWhatsAppDocumentation;
const API_URL_WHATSAPP_NOTIFICATION = environment.urlWhatsAppNotificacion;
@Injectable({
  providedIn: 'root'
})
export class WhatsAppService {
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  })
  constructor(private http: HttpClient) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.statusText !== undefined) {
        if (error.statusText === "Unknown Error") {
          console.log('No se ha podido conectar con el servidor, revisar la conexion a Internet');
        }
      } else {
        console.log(`${operation} failed: ${error.error}`);
      }
      return of(result as T);
    };
  }  
  ObtenerIniciarCliente() {
    const url = `${API_URL_WHATSAPP_NOTIFICATION}api/WhatsApp/iniciar-cliente`;
    return this.http.post(url, { headers: this.headers }).pipe(
      map((response:any)=>{        
        return response;
      }),
      catchError(this.handleError<any>('ObtenerQR', null))
    );
  }

  ObtenerNumeroSesion(){
    return this.http.get<any>(API_URL_WHATSAPP_NOTIFICATION + `api/WhatsApp/obtenerNumero`)
      .pipe(catchError(this.handleError<any>('ObtenerNumeroSesion', null)));
  }

  
}
