import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Router } from "@angular/router";
import { ComboDTO } from '../models/combo-dto';
import { NgxSpinnerService } from "ngx-spinner";
import { TiposRolesEnum } from '../enum/tipos-roles';
import { EstilosService } from '../services/estilos.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EstadosMensajesAPIEnum } from '../enum/estado-mensajes-api';
import { CargarScriptsService } from '../services/cargar-scripts.service';
import { TamanioArchivosEnum, TipoArchivosEnum } from '../enum/tamanio-archivos';
import { MensajeArchivosNotificacionesDTO } from '../models/mensajes-archivos-notificaciones-dto';
import { CriterioBusquedaObtenerDetalleDTO } from '../models/criterio-busqueda-obtener-detalle-dto';
import { ListaObtenerDetalleDTO } from '../models/lista-obtener-detalle-dto';
import { RecepcionarUsuarioComponent } from '../recepcionar-usuario/recepcionar-usuario.component';
import { WhatsAppService } from '../services/whatsapp.service';
import { LoggerService } from '../services/logger.service';
import { SettingsService } from '../auth/settings/settings.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent implements OnInit {
  dataFormGroup: FormGroup;
  dataFormGroupBusqueda: FormGroup;
  mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
  archivoActual: string;
  isImage: boolean = false;
  isVideo: boolean = false;
  isAudio: boolean = false;
  isApplication: boolean = false;
  isAdjunto: boolean = false;

  esAdministrador: boolean = false;
  esSupervisor: boolean = false;
  esEjecutivo: boolean = false;
  idPersonal: number = 0;

  tamanioArchivo: number = TamanioArchivosEnum.Documento / 1000000;
  tamanioAudio: number = TamanioArchivosEnum.Audio / 1000000;
  tamanioImage: number = TamanioArchivosEnum.Imagen / 1000000;
  tamanioVideo: number = TamanioArchivosEnum.Video / 1000000;

  styleListSmallButtonRoundedPrimary: String = '';
  styleListSmallButtonRoundedPending: String = '';
  styleListSmallButtonRoundedDark: String = '';
  styleListSmallButtonRoundedWarning: String = '';
  styleListSmallButtonRoundedSuccess: String = '';
  styleListSmallButtonRoundedSecondary: String = '';
  styleListSmallButtonRoundedDanger: String = '';
  styleIconButtonSmall: String = '';
  styleSearchChat: String = '';
  styleTextareaChat: String = '';
  styleUbicacionChat: String = '';
  styleDivMainModal: String = '';
  styleDivHeaderModal: String = '';
  styleAX: String = '';
  styleIconX: String = '';
  styleLabel: String = '';
  styleInputText: String = '';
  styleButtonPrimary: String = '';
  styleButtonDanger: String = '';
  styleIconButtonText: String = '';
  styleButtonSearch: String = '';

  numeroOrigen: string = '';
  urlArchivo: string = '';
  comboUbicacion: ComboDTO[] = [];    
  listaObtenerDetalle: ListaObtenerDetalleDTO[] = [];
  constructor(
    private logger: LoggerService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public settings: SettingsService,
    private servicio: ServiciosService,
    private estilosService: EstilosService,
    private cargarScriptsService: CargarScriptsService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private whatsAppService:WhatsAppService
  ) {
    this.styleIconButtonSmall = this.estilosService.StyleIconButtonSmall();
    this.styleListSmallButtonRoundedPrimary = this.estilosService.StyleListSmallButtonRoundedPrimary();
    this.styleListSmallButtonRoundedPending = this.estilosService.StyleListSmallButtonRoundedPending();
    this.styleListSmallButtonRoundedDark = this.estilosService.StyleListSmallButtonRoundedDark();
    this.styleListSmallButtonRoundedWarning = this.estilosService.StyleListSmallButtonRoundedWarning();
    this.styleListSmallButtonRoundedSuccess = this.estilosService.StyleListSmallButtonRoundedSuccess();
    this.styleListSmallButtonRoundedSecondary = this.estilosService.StyleListSmallButtonRoundedSecondary();
    this.styleListSmallButtonRoundedDanger = this.estilosService.StyleListSmallButtonRoundedDanger();
    this.styleSearchChat = this.estilosService.StyleSearchChat();
    this.styleTextareaChat = this.estilosService.StyleTextareaChat();
    this.styleUbicacionChat = this.estilosService.StyleUbicacionChat();
    this.styleDivMainModal = this.estilosService.StyleDivMainModal();
    this.styleDivHeaderModal = this.estilosService.StyleDivHeaderModal();
    this.styleAX = this.estilosService.StyleAX();
    this.styleIconX = this.estilosService.StyleIconX();
    this.styleLabel = this.estilosService.StyleLabel();
    this.styleInputText = this.estilosService.StyleInputText();
    this.styleButtonPrimary = this.estilosService.StyleButtonPrimary();
    this.styleButtonDanger = this.estilosService.StyleButtonDanger();
    this.styleIconButtonText = this.estilosService.StyleIconButtonText();
    this.styleButtonSearch = this.estilosService.StyleButtonSearch();
    this.dataFormGroup = new FormGroup({
      inputNumeroDestino: new FormControl('', [Validators.required]),
      inputFile: new FormControl(''),
      inputMensaje: new FormControl(''),
    });
    this.dataFormGroupBusqueda = new FormGroup({
      inputNroCelular: new FormControl(''),
      inputFechaInicio: new FormControl(''),
      inputFechaFin: new FormControl(''),
    });
  }


  ngOnInit(): void {
    let idRol = this.settings.getUserSetting('idRol');
    this.idPersonal = this.settings.getUserSetting('idPersonal');
    if (idRol == TiposRolesEnum.Administrador) {
      this.esAdministrador = true;
      this.esSupervisor = false;
      this.esEjecutivo = false;
    }
    else if (idRol == TiposRolesEnum.Supervisor) {
      this.esAdministrador = false;
      this.esSupervisor = true;
      this.esEjecutivo = false;
    }
    else if (idRol == TiposRolesEnum.Ejecutivo) {
      this.esAdministrador = false;
      this.esSupervisor = false;
      this.esEjecutivo = true;
    }
    this.EjecutarScript();
    this.ObtenerNumeroSesion();    
  }

  ObtenerNumeroSesion() {  
    this.spinner.show().then();
    this.whatsAppService.ObtenerNumeroSesion()
      .subscribe({
        next: (data) => {          
          console.log(data.numero);
          this.numeroOrigen = data.numero;                  
        },
        error: (e) => {
          console.log(e);
          this.spinner.hide().then();
        },
        complete: () => { this.spinner.hide().then(); }
      });
   }

  Atras() {
    this.router.navigate(['authenticated/dashboard']).then();
  }

  EnviarMensaje() {
    for (let c in this.dataFormGroup.controls) {
      this.dataFormGroup.controls[c].markAsTouched();
    }
    if (this.dataFormGroup.valid) {
      if (!this.isAdjunto) {
        this.mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
        this.mensajesArchivosNotificacionesDTO.numeroDestino = '51' + this.dataFormGroup.controls['inputNumeroDestino'].value;
        this.mensajesArchivosNotificacionesDTO.numeroOrigen = this.numeroOrigen;
        this.mensajesArchivosNotificacionesDTO.mensage = this.dataFormGroup.controls['inputMensaje'].value;
      }
      else {
        this.mensajesArchivosNotificacionesDTO.mensage = this.dataFormGroup.controls['inputMensaje'].value;
      }
      console.log('Enviado: ', this.mensajesArchivosNotificacionesDTO);

      this.spinner.show().then();
      this.servicio.EnviarNotificacion(this.mensajesArchivosNotificacionesDTO)
        .subscribe({
          next: (data: any) => {
            console.log(data);
            if (data != null) {
              if (data.estado) {
                if (data.estadoMensaje == EstadosMensajesAPIEnum.Enviado) {
                  this.logger.notifica('El mensaje se envió correctamente', 'success');
                }
                else if (data.estadoMensaje == EstadosMensajesAPIEnum.Pendiente) {
                  this.logger.notifica('El mensaje esta pendiente para su envío.', 'warning');
                }
                else if (data.estadoMensaje == EstadosMensajesAPIEnum.Error) {
                  this.logger.notifica('Error al enviar el mensaje.', 'error');
                }
              }
              else {
                this.logger.notifica('Ocurrió un error en el proceso.', 'error');
              }
            }
            else {
              this.logger.notifica('Ocurrió un error en el proceso.', 'error');
            }
            this.CerrarModal();
          },
          error: (e) => {
            this.logger.notifica('Ocurrió un error en enviar el mensaje.', 'error');
            this.spinner.hide().then();
            this.CerrarModal();
          },
          complete: () => {
            this.spinner.hide().then();
            this.CerrarModal();
          }
        });
    }
    else {
      this.logger.notifica('Hay campos obligatorios.', 'error');
      if (this.isAdjunto) {
        this.CerrarModal();
      }
    }
  }

  TriggerFile(fileInput: HTMLElement, isApplication: boolean, isImage: boolean, isAudio: boolean, isVideo: boolean) {
    this.isApplication = isApplication;
    this.isVideo = isVideo;
    this.isAudio = isAudio;
    this.isImage = isImage;
    fileInput.click();
  }

  VerificarArchivoBase64(event: any, templateRef: TemplateRef<any>) {
    for (let c in this.dataFormGroup.controls) {
      this.dataFormGroup.controls[c].markAsTouched();
    }
    if (!this.dataFormGroup.valid) {
      this.logger.notifica('Hay campos obligatorios.', 'error');
      this.LimpiarFormularioArchivo();
      return;
    }
    let arrayArchivos: Array<string> = ['application', 'video', 'audio', 'image'];
    let tipo = (event.target.files[0].type).split('/');

    if (arrayArchivos.includes(tipo[0])) {
      this.isAdjunto = true;
      if (tipo[0] == TipoArchivosEnum.Archivo && this.isApplication) {
        let arrayDocumentos: Array<string> = ['pdf'];
        if (arrayDocumentos.includes(tipo[1])) {
          if (event.target.files[0].size < TamanioArchivosEnum.Documento) {
            this.ConvertirArchivoBase64(event, templateRef);
          }
          else {
            this.logger.notifica(`El documento adjunto sobrepasa la cantidad máxima permitida de ${TamanioArchivosEnum.Documento / 1000000} MB`, 'error');
            this.LimpiarFormularioArchivo();
          }
        }
        else {
          this.logger.notifica('Debe se permite documentos PDF', 'error');
          this.LimpiarFormularioArchivo();
        }
      }
      else if (tipo[0] == TipoArchivosEnum.Video && this.isVideo) {
        let arrayDocumentos: Array<string> = ['video'];
        if (arrayDocumentos.includes(tipo[0])) {
          if (event.target.files[0].size < TamanioArchivosEnum.Video) {
            this.ConvertirArchivoBase64(event, templateRef);
          }
          else {
            this.logger.notifica(`El documento adjunto sobrepasa la cantidad máxima permitida de ${TamanioArchivosEnum.Video / 1000000} MB`, 'error');
            this.LimpiarFormularioArchivo();
          }
        }
        else {
          this.logger.notifica('Debe se permite archivo del tipo video', 'error');
          this.LimpiarFormularioArchivo();
        }
      }
      else if (tipo[0] == TipoArchivosEnum.Audio && this.isAudio) {
        let arrayDocumentos: Array<string> = ['audio'];
        if (arrayDocumentos.includes(tipo[0])) {
          if (event.target.files[0].size < TamanioArchivosEnum.Audio) {
            this.ConvertirArchivoBase64(event, templateRef);
          }
          else {
            this.logger.notifica(`El documento adjunto sobrepasa la cantidad máxima permitida de ${TamanioArchivosEnum.Audio / 1000000} MB`, 'error');
            this.LimpiarFormularioArchivo();
          }
        }
        else {
          this.logger.notifica('Debe se permite archivo del tipo audio', 'error');
          this.LimpiarFormularioArchivo();
        }
      }
      else if (tipo[0] == TipoArchivosEnum.Imagen && this.isImage) {
        let arrayDocumentos: Array<string> = ['image'];
        if (arrayDocumentos.includes(tipo[0])) {
          if (event.target.files[0].size < TamanioArchivosEnum.Imagen) {
            this.ConvertirArchivoBase64(event, templateRef);
          }
          else {
            this.logger.notifica(`El documento adjunto sobrepasa la cantidad máxima permitida de ${TamanioArchivosEnum.Imagen / 1000000} MB`, 'error');
            this.LimpiarFormularioArchivo();
          }
        }
        else {
          this.logger.notifica('Debe se permite archivo del tipo imagen', 'error');
          this.LimpiarFormularioArchivo();
        }
      }
      else {
        this.logger.notifica('No se puede adjuntar el archivo.', 'error');
        this.LimpiarFormularioArchivo();
      }
    }
    else {
      this.logger.notifica('No se puede adjuntar el archivo, tiene que ser del tipo PDF, imagen, audio o video.', 'error');
      this.LimpiarFormularioArchivo();
    }
  }

  ConvertirArchivoBase64(event: any, templateRef: TemplateRef<any>) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      let splitBase64 = base64.split('base64,');
      this.mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
      this.mensajesArchivosNotificacionesDTO.numeroDestino = '51' + this.dataFormGroup.controls['inputNumeroDestino'].value;
      this.mensajesArchivosNotificacionesDTO.numeroOrigen = this.numeroOrigen;
      //this.mensajesArchivosNotificacionesDTO.mensage = this.dataFormGroup.controls['inputMensaje'].value;
      this.mensajesArchivosNotificacionesDTO.adjuntoBase64 = splitBase64[1];
      this.mensajesArchivosNotificacionesDTO.nombreArchivo = event.target.files[0].name;
      this.mensajesArchivosNotificacionesDTO.mimeType = event.target.files[0].type;
      this.mensajesArchivosNotificacionesDTO.nroByte = event.target.files[0].size;

      this.archivoActual = base64;
      this.bsModalRef = this.modalService.show(templateRef, { backdrop: 'static', class: 'modal-lg' });
      this.EjecutarScript();
      // this.EnviarMensaje();
    };
  }

  AbrirModalQR(){
    this.bsModalRef = this.modalService.show(RecepcionarUsuarioComponent, { backdrop: 'static', class: 'modal-lg' });
  }

  FiltrarNumeros(e: any) {
    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 9) e.returnValue = false;
  }

  DevolverImagen(mimeType: string, base64: string) {
    return `data:${mimeType};base64,${base64}`;
  }

  DevolverAudio(mimeType: string, base64: string) {
    return `data:${mimeType};base64,${base64}`;
  }

  DevolverVideo(mimeType: string, base64: string) {
    return `data:${mimeType};base64,${base64}`;
  }

  DevolverMimeType(mime: string) {
    return `${mime}`;
  }


  DescargarArchivo(base64: any, fileName: any) {
    const src = `${base64}`;
    const link = document.createElement("a")
    link.href = src
    link.download = fileName
    link.click()

    link.remove()
  }

  VerificarTexto(texto: string) {
    if (texto.includes('http')) {
      return `<a [href]="${texto}" target="_blank">${texto}</a>`
    }
    else {
      return texto
    }
  }

  EjecutarScript() {
    this.cargarScriptsService.CargarArchivoJS('components/base/lucide.js', 'id-base-lucide');
    this.cargarScriptsService.CargarArchivoJS('vendors/transition.js', 'id-transition');
    this.cargarScriptsService.CargarArchivoJS('pages/chat.js', 'id-chat');
    this.cargarScriptsService.CargarArchivoJS('components/base/tippy.js', 'id-base-tippy');
  }

  CerrarModal() {
    this.mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
    this.LimpiarFormulario();
    this.bsModalRef.hide();
  }

  LimpiarFormulario() {
    this.isAdjunto = false;
    this.isApplication = false;
    this.isImage = false;
    this.isVideo = false;
    this.isAudio = false;
    this.urlArchivo = '';
    this.dataFormGroup.reset();
  }

  LimpiarFormularioArchivo() {
    this.isAdjunto = false;
    this.isApplication = false;
    this.isImage = false;
    this.isVideo = false;
    this.isAudio = false;
    this.urlArchivo = '';
    this.mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
    this.dataFormGroup.controls['inputFile'].reset();
  }

  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  /// Buscar por criterio
  /// </summary>
  BuscarPorCriterio() {

    let criterioBusqueda = new CriterioBusquedaObtenerDetalleDTO();
    let textoCriterioBusqueda = "";

    let nroCelular = this.dataFormGroupBusqueda.controls['inputNroCelular'].value;
    let fechaInicio = this.dataFormGroupBusqueda.controls['inputFechaInicio'].value;
    let fechaFin = this.dataFormGroupBusqueda.controls['inputFechaFin'].value;

    if (nroCelular != '' && nroCelular != null)
      criterioBusqueda.numeroCliente = '51' + nroCelular;

    if (fechaInicio != '' && fechaInicio != null){
      criterioBusqueda.fechaInicio = moment(fechaInicio).format('YYYY-MM-DD');
      if (fechaFin == '' || fechaFin == null)
        criterioBusqueda.fechaFin = moment(fechaInicio).format('YYYY-MM-DD');
    }

    if (fechaFin != '' && fechaFin != null){
      criterioBusqueda.fechaFin = moment(fechaFin).format('YYYY-MM-DD');
      if (fechaInicio == '' || fechaInicio == null)
        criterioBusqueda.fechaInicio = moment(fechaFin).format('YYYY-MM-DD');
    }

    new Map(Object.entries(criterioBusqueda)).forEach((value, key, map) => {
      textoCriterioBusqueda += `${key}=${value}&`;
    });    
    textoCriterioBusqueda = textoCriterioBusqueda.substring(0, textoCriterioBusqueda.length - 1);
    if (textoCriterioBusqueda != "") {
      this.spinner.show().then();
      this.servicio.ObtenerDetalle(criterioBusqueda)
        .subscribe({
          next: (data: ListaObtenerDetalleDTO[]) => {
            if(data!=null){
              this.listaObtenerDetalle = data;
              console.log(this.listaObtenerDetalle);
            }
            else{
              this.logger.error('Error en la búsqueda, po favor revise los criterios si son correctos.');
            }
          },
          error: (e) => {
            console.log(e);
            if (e.status == '400') {
              this.logger.error('Los criterio ingresados no son válidos.');
            } else {
              this.logger.error('Error en la búsqueda');
            }
            this.spinner.hide().then();

          },
          complete: () => {
            this.spinner.hide().then();
          }
        });      
    }
    else{
      this.logger.error('Debe ingresar por lo menos 1 criterio de búsqueda');
    }
  }

  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  /// Limpiar formulario
  /// </summary>
  LimpiarFormularioBusqueda() {
    this.listaObtenerDetalle = [];
    this.dataFormGroupBusqueda.reset();
  }

  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  ///  Permite resumir al control para el interfaz.
  /// </summary>
  get Controls() {
    return this.dataFormGroup.controls;
  }

  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  ///  Permite resumir al control para el interfaz.
  /// </summary>
  get ControlsBusqueda() {
    return this.dataFormGroupBusqueda.controls;
  }

  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  /// Funciones para mostrar notificaciones.
  /// </summary>
  MostrarNotificacionSuccess(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'success',
      title: titulo,
      text: mensaje,
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
      }
    })
  }

  MostrarNotificacionEliminar(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: mensaje,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Confirmar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
      }
    })
  }

  MostrarNotificacionInfo(titulo: string, mensaje: string) {
    console.log('información');
    Swal.fire({
      icon: 'info',
      title: titulo,
      text: mensaje
    });
  }

  MostrarNotificacionWarning(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: mensaje
    });
  }

  MostrarNotificacionError(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      html: `<div class="message-text-error">${mensaje} </div>`,
    });
  }

}

