import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import { Router } from '@angular/router';
//import {UserService} from '../services/user.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }
  bodyRequest:any="";
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('auth_token');
    this.bodyRequest=request.body;
    let req = request;    
    if (token) {
      req = request.clone({
        setHeaders: {
          authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(req).pipe(
      catchError((error) => {
        let errorMessage = '';        
        if (error instanceof ErrorEvent) {          
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {          
          errorMessage = `Server-side error: ${error.status} ${error.message}`;
        }                
        return throwError(() => error);
      }),
    );
  }
  /*constructor(private authenticationService: UserService) {
  }*/

  /*intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.userlogged;
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    return next.handle(request);
  }*/
}
