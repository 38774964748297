import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/auth/settings/settings.service';
import { TiposRolesEnum } from 'src/app/enum/tipos-roles';

@Component({
  selector: 'app-siderbar-mobile',
  templateUrl: './siderbar-mobile.component.html',
  styleUrls: ['./siderbar-mobile.component.scss']
})
export class SiderbarMobileComponent implements OnInit {

  tituloMenuMobile: string = 'dashboard';
  tituloSubMenuMobile: string = '';  

  esAdministrador: boolean = false;
  esSupervisor: boolean = false;
  esEjecutivo: boolean = false;

  constructor(
    public settings: SettingsService,
  ) {
  }
  ngOnInit(): void {
    let idRol = this.settings.getUserSetting('idRol');
    if(idRol == TiposRolesEnum.Administrador) {
      this.esAdministrador = true;
      this.esSupervisor = false;
      this.esEjecutivo = false;
    }
    else if(idRol == TiposRolesEnum.Supervisor) {
      this.esAdministrador = false;
      this.esSupervisor = true;
      this.esEjecutivo = false;
    }
    else if(idRol == TiposRolesEnum.Ejecutivo) {
      this.esAdministrador = false;
      this.esSupervisor = false;
      this.esEjecutivo = true;
    }
  }

  CerrarSiderbarMobile(titulo: string, tituloSubMenu: string){  
    this.tituloMenuMobile = titulo;
    this.tituloSubMenuMobile = tituloSubMenu;   
    let yourElem = <HTMLElement>document.querySelector('.mobile-menu-toggler');
    yourElem.click();
  }

}
