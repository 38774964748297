import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Router } from "@angular/router";
import { ComboDTO } from '../models/combo-dto';
import { NgxSpinnerService } from "ngx-spinner";
import { TiposRolesEnum } from '../enum/tipos-roles';
import { EstilosService } from '../services/estilos.service';
import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EstadosMensajesAPIEnum } from '../enum/estado-mensajes-api';
import { CargarScriptsService } from '../services/cargar-scripts.service';
import { TamanioArchivosEnum, TipoArchivosEnum } from '../enum/tamanio-archivos';
import { MensajeArchivosNotificacionesDTO } from '../models/mensajes-archivos-notificaciones-dto';
import { CriterioBusquedaObtenerDetalleDTO } from '../models/criterio-busqueda-obtener-detalle-dto';
import { ListaObtenerDetalleDTO } from '../models/lista-obtener-detalle-dto';
import { AsignacionConversacionDTO } from '../models/asignacion-conversacion-dto';
import { MensajeDestinatarioDTO } from '../models/mensajes-destinatario-dto';
import { TiposMensajesEnum } from '../enum/tipos-mensajes';
import { TiposArchivosEnum } from '../enum/tipos-archivos';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { NuevoChatWhatsappComponent } from './nuevo-chat-whatsapp/nuevo-chat-whatsapp.component';
import { WhatsAppService } from '../services/whatsapp.service';
import { CriterioBusquedaObtenerResumenDTO } from '../models/criterio-busqueda-obtener-resumen-dto';
import { LoggerService } from '../services/logger.service';
import { SettingsService } from '../auth/settings/settings.service';

const urlHubQR = environment.apiSignalBase + 'hub-qr';
const urlHubChat = environment.apiSignalBase + 'hub-chat';

@Component({
  selector: 'app-chat-whatsapp',
  templateUrl: './chat-whatsapp.component.html',
  styleUrls: ['./chat-whatsapp.component.scss']
})
export class ChatWhatsappComponent implements OnInit {

  private hubConnectionQR: signalR.HubConnection;
  private hubConnectionChat: signalR.HubConnection;
  conectionIdQR: string | null | undefined = '';
  conectionIdChat: string | null | undefined = '';
  timerSignalrQr!: NodeJS.Timer;
  timerSignalrChat!: NodeJS.Timer;

  dataFormGroup: FormGroup;
  dataFormGroupBusqueda: FormGroup;  
  mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
  archivoActual: string;
  isImage: boolean = false;
  isVideo: boolean = false;
  isAudio: boolean = false;
  isApplication: boolean = false;
  isAdjunto: boolean = false;

  esAdministrador: boolean = false;
  esSupervisor: boolean = false;
  esEjecutivo: boolean = false;
  idPersonal: number = 0;

  tamanioArchivo: number = TamanioArchivosEnum.Documento / 1000000;
  tamanioAudio: number = TamanioArchivosEnum.Audio / 1000000;
  tamanioImage: number = TamanioArchivosEnum.Imagen / 1000000;
  tamanioVideo: number = TamanioArchivosEnum.Video / 1000000;

  styleListSmallButtonRoundedPrimary: String = '';
  styleListSmallButtonRoundedPending: String = '';
  styleListSmallButtonRoundedDark: String = '';
  styleListSmallButtonRoundedWarning: String = '';
  styleListSmallButtonRoundedSuccess: String = '';
  styleListSmallButtonRoundedSecondary: String = '';
  styleListSmallButtonRoundedDanger: String = '';
  styleIconButtonSmall: String = '';
  styleSearchChat: String = '';
  styleTextareaChat: String = '';
  styleUbicacionChat: String = '';
  styleDivMainModal: String = '';
  styleDivHeaderModal: String = '';
  styleAX: String = '';
  styleIconX: String = '';
  styleLabel: String = '';
  styleInputText: String = '';
  styleButtonPrimary: String = '';
  styleButtonDanger: String = '';
  styleIconButtonText: String = '';
  styleButtonSearch: String = '';

  numeroOrigen: string = '';
  urlArchivo: string = '';
  comboUbicacion: ComboDTO[] = [];  
  listaObtenerDetalle: ListaObtenerDetalleDTO[] = [];

  listadoAsignacionConversacion: AsignacionConversacionDTO[] = [];
  listadoAsignacionConversacionTemporal: AsignacionConversacionDTO[] = [];
  listadoMensajesDestinatario: MensajeDestinatarioDTO[] = [];
  listadoMensajesDestinatarioNuevos: MensajeDestinatarioDTO[] = [];
  idRemitente: number = 0;
  verBotonesMultimedia: boolean = false;
  destinatarioElegido: string = '';
  destinatarioFechaElegido: Date;
  idTipoMensaje: number = 1;
  listaAsignacionConversacion = new AsignacionConversacionDTO();
  idAsignacionConversacionActivo: number = 0;
  idTexto = TiposArchivosEnum.Texto;
  idArchivo = TiposArchivosEnum.Archivo;
  idImagen = TiposArchivosEnum.Imagen;
  idAudio = TiposArchivosEnum.Audio;
  idVideo = TiposArchivosEnum.Video;
  idUbicacion = TiposArchivosEnum.Ubicacion;
  idUrl = TiposArchivosEnum.Ubicacion;
  stringQR: string = '';
  
  constructor(    
    private logger: LoggerService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public settings: SettingsService,
    private servicio: ServiciosService,
    private estilosService: EstilosService,
    private cargarScriptsService: CargarScriptsService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private whatsAppService: WhatsAppService
  ) {
    this.styleIconButtonSmall = this.estilosService.StyleIconButtonSmall();
    this.styleListSmallButtonRoundedPrimary = this.estilosService.StyleListSmallButtonRoundedPrimary();
    this.styleListSmallButtonRoundedPending = this.estilosService.StyleListSmallButtonRoundedPending();
    this.styleListSmallButtonRoundedDark = this.estilosService.StyleListSmallButtonRoundedDark();
    this.styleListSmallButtonRoundedWarning = this.estilosService.StyleListSmallButtonRoundedWarning();
    this.styleListSmallButtonRoundedSuccess = this.estilosService.StyleListSmallButtonRoundedSuccess();
    this.styleListSmallButtonRoundedSecondary = this.estilosService.StyleListSmallButtonRoundedSecondary();
    this.styleListSmallButtonRoundedDanger = this.estilosService.StyleListSmallButtonRoundedDanger();
    this.styleSearchChat = this.estilosService.StyleSearchChat();
    this.styleTextareaChat = this.estilosService.StyleTextareaChat();
    this.styleUbicacionChat = this.estilosService.StyleUbicacionChat();
    this.styleDivMainModal = this.estilosService.StyleDivMainModal();
    this.styleDivHeaderModal = this.estilosService.StyleDivHeaderModal();
    this.styleAX = this.estilosService.StyleAX();
    this.styleIconX = this.estilosService.StyleIconX();
    this.styleLabel = this.estilosService.StyleLabel();
    this.styleInputText = this.estilosService.StyleInputText();
    this.styleButtonPrimary = this.estilosService.StyleButtonPrimary();
    this.styleButtonDanger = this.estilosService.StyleButtonDanger();
    this.styleIconButtonText = this.estilosService.StyleIconButtonText();
    this.styleButtonSearch = this.estilosService.StyleButtonSearch();
    this.dataFormGroup = new FormGroup({
      inputFile: new FormControl(''),
      inputMensaje: new FormControl(''),
    });
    this.dataFormGroupBusqueda = new FormGroup({
      inputNroCelular: new FormControl(''),
      inputFechaInicio: new FormControl(''),
      inputFechaFin: new FormControl(''),
      inputBuscarChat: new FormControl(''),
    });

    //signalR
    this.hubConnectionQR = new signalR.HubConnectionBuilder()
      .withUrl(urlHubQR)
      .withAutomaticReconnect([0, 1000, 5000, 10000])
      .configureLogging(signalR.LogLevel.Information)
      .build();
    this.hubConnectionChat = new signalR.HubConnectionBuilder()
      .withUrl(urlHubChat)
      .withAutomaticReconnect([0, 1000, 5000, 10000])
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.iniciarBucleConexionSignalQR();
    this.iniciarBucleConexionSignalChat();

    this.hubConnectionQR
      .start()
      .then(() => {
        console.log('Connection qr started');
        let conectionId = this.hubConnectionQR?.connectionId;

        //unirse a grupo
        this.hubConnectionQR?.invoke('AgregarGrupo', conectionId, "grupo-recepcion-qr");

        ////Eliminar estos eventos
        this.hubConnectionQR.on('QR_RecepcionQr', (data: any) => {
          const received = `Recibido - QR_RecepcionQr:`;
          console.log(received, data);
          let qr = JSON.parse(data.payload);
          this.stringQR = 'data:image/jpeg;base64,' + qr.QrCodeBase64;
        });

        this.hubConnectionQR.on('Grupo_RecepcionUnirse', (data: any) => {
          const received = `Recibido - Grupo_RecepcionUnirse: ${data}`;
          console.log(received);
        });
      })
      .catch(err => console.log('Error while starting connection: ' + err));
    this.hubConnectionQR.onclose(() => {
      console.log('Desconectado Notificaciones QR: ');
      this.iniciarBucleConexionSignalQR();
    });

    this.hubConnectionChat
      .start()
      .then(() => {
        console.log('Connection sr started');
        let conectionId = this.hubConnectionChat?.connectionId;

        //unirse a grupo
        this.hubConnectionChat?.invoke('AgregarGrupo', conectionId, "chat-nuevo-mensaje-general");
        
        this.hubConnectionChat.on('Chat_RecepcionNuevoMensajeGeneral', (data: any) => {
          const received = `Recibido - Chat_RecepcionNuevoMensajeGeneral:`;
          console.log(received, data);
          if (data.esEntrante) {
            console.log(this.listadoMensajesDestinatario);
            let buscarNumeroCliente = this.listadoMensajesDestinatario.find(x => x.numeroCliente == data.numeroCliente);
            if (buscarNumeroCliente != null) {
              this.ObtenerConversacionPorIdDestinatario(this.listaAsignacionConversacion);
            } else {
              this.listadoAsignacionConversacion.forEach(element => {
                if (element.numeroCliente == data.numeroCliente) {
                  element.contador = (element.contador + 1);
                }
              });
            }
          }

        });

        this.hubConnectionChat.on('Grupo_RecepcionUnirse', (data: any) => {
          const received = `Recibido - Grupo_RecepcionUnirse: ${data}`;
          console.log(received);
        });
      })
      .catch(err => console.log('Error while starting connection: ' + err));
    this.hubConnectionChat.onclose(() => {
      console.log('Desconectado Notificaciones CHAT: ');
      this.iniciarBucleConexionSignalChat();
    });



  }


  ngOnInit(): void {
    let idRol = this.settings.getUserSetting('idRol');
    this.idPersonal = this.settings.getUserSetting('idPersonal');
    if (idRol == TiposRolesEnum.Administrador) {
      this.esAdministrador = true;
      this.esSupervisor = false;
      this.esEjecutivo = false;
    }
    else if (idRol == TiposRolesEnum.Supervisor) {
      this.esAdministrador = false;
      this.esSupervisor = true;
      this.esEjecutivo = false;
    }
    else if (idRol == TiposRolesEnum.Ejecutivo) {
      this.esAdministrador = false;
      this.esSupervisor = false;
      this.esEjecutivo = true;
    }
    this.EjecutarScript();
    this.ObtenerNumeroSesion();        
  }

  scrollToBottom() {    
    let element: any = document.querySelector('[formcontrolname="inputMensaje"]');
        element.scrollIntoView();
  }

  ngOnDestroy(): void {
    // clearInterval(this.timerSignalr);
  }


  /**
   * Inicia un bucle para la reconexion de signal
   * Autor: Ansoli Espinoza
   */
  iniciarBucleConexionSignalQR() {
    var refToThis = this;
    // this.timerSignalrQr = setInterval(function () {
    //   refToThis.startHubQR();
    // }, 10000);
  }
  /**
  * Inicia un bucle para la reconexion de signal
  * Autor: Ansoli Espinoza
  */
  iniciarBucleConexionSignalChat() {
    var refToThis = this;
    // this.timerSignalrChat = setInterval(function () {
    //   refToThis.startHubCHAT();
    // }, 10000);
  }

  /**
   * Inicia la conexion con el Hub de signal
   * Autor: Ansoli Espinoza
   */
  startHubQR() {
    console.log('Estableciendo conexion al Hub QR ...');

    this.hubConnectionQR.start().then(con => {
      // this.settings.isAuthenticated();
      // this.usuarioSignal = this.settings.getUserSetting('usuario');

      console.log('Eliminando BucleConexion QR');
      // clearInterval(this.timerSignalr);

      this.conectionIdQR = this.hubConnectionQR?.connectionId;
      console.log('conectionIdQR', this.hubConnectionQR?.connectionId);

      //unirme a grupo
      this.hubConnectionQR?.invoke('AgregarGrupo', this.conectionIdQR, 'chat-nuevo-mensaje');

      this.SuscribirseEventosQR();

    }).catch(err => console.error('Error al conectar al Hub QR:', err.toString()));
  }
  /**
 * Inicia la conexion con el Hub de signal
 * Autor: Ansoli Espinoza
 */
  startHubChat() {
    console.log('Estableciendo conexion al Hub CHAT...');

    this.hubConnectionChat.start().then(con => {
      // this.settings.isAuthenticated();
      // this.usuarioSignal = this.settings.getUserSetting('usuario');

      console.log('Eliminando BucleConexion CHAT');
      // clearInterval(this.timerSignalr);

      this.conectionIdChat = this.hubConnectionChat?.connectionId;
      console.log('conectionIdChat', this.hubConnectionChat?.connectionId);

      //unirme a grupo
      this.hubConnectionChat?.invoke('AgregarGrupo', this.conectionIdChat, 'chat-nuevo-mensaje');

      this.SuscribirseEventosChat();

    }).catch(err => console.error('Error al conectar al Hub:', err.toString()));
  }

  /**
* Suscripcion a los eventos de Signal
* Autor: Ansoli Espinoza
*/
  SuscribirseEventosQR() {
    this.hubConnectionQR.on('QR_RecepcionQr', (data: any) => {
      const received = `Recibido - QR_RecepcionQr:`;
      console.log(received, data);
      console.log(JSON.parse(data));
    });

    this.hubConnectionQR.on('Grupo_RecepcionUnirse', (data: any) => {
      const received = `Recibido - Grupo_RecepcionUnirse: ${data}`;
      console.log(received);
    });
  }
  /**
* Suscripcion a los eventos de Signal
* Autor: Ansoli Espinoza
*/
  SuscribirseEventosChat() {
    this.hubConnectionChat.on('Chat_RecepcionNuevoMensajeGeneral', (data: any) => {
      const received = `Recibido - Chat_RecepcionNuevoMensajeGeneral:`;
      console.log(received, data);
    });

    this.hubConnectionChat.on('Grupo_RecepcionUnirse', (data: any) => {
      const received = `Recibido - Grupo_RecepcionUnirse: ${data}`;
      console.log(received);
    });
  }

  Atras() {
    this.router.navigate(['authenticated/dashboard']).then();
  }

  EnviarMensaje() {
    for (let c in this.dataFormGroup.controls) {
      this.dataFormGroup.controls[c].markAsTouched();
    }
    if (this.dataFormGroup.valid) {
      if (!this.isAdjunto) {
        this.mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
        this.mensajesArchivosNotificacionesDTO.numeroDestino = this.listaAsignacionConversacion.numeroCliente;
        this.mensajesArchivosNotificacionesDTO.numeroOrigen = this.numeroOrigen;
        this.mensajesArchivosNotificacionesDTO.mensage = this.dataFormGroup.controls['inputMensaje'].value;
      }
      else {
        this.mensajesArchivosNotificacionesDTO.mensage = this.dataFormGroup.controls['inputMensaje'].value;
      }
      console.log('Enviado: ', this.mensajesArchivosNotificacionesDTO);

      this.spinner.show().then();
      this.servicio.EnviarNotificacion(this.mensajesArchivosNotificacionesDTO)
        .subscribe({
          next: (data: any) => {
            console.log(data);
            if (data != null) {
              if (data.estado) {
                if (data.estadoMensaje == EstadosMensajesAPIEnum.Enviado) {
                  this.logger.notifica('El mensaje se envió correctamente', 'success');
                }
                else if (data.estadoMensaje == EstadosMensajesAPIEnum.Pendiente) {
                  this.logger.notifica('El mensaje esta pendiente para su envío.', 'warning');
                }
                else if (data.estadoMensaje == EstadosMensajesAPIEnum.Error) {
                  this.logger.notifica('Error al enviar el mensaje.', 'error');
                }
              }
              else {
                this.logger.notifica('Ocurrió un error en el proceso.', 'error');
              }
            }
            else {
              this.logger.notifica('Ocurrió un error en el proceso.', 'error');
            }
            this.CerrarModal();
          },
          error: (e) => {
            this.logger.notifica('Ocurrió un error en enviar el mensaje.', 'error');
            this.spinner.hide().then();
            this.CerrarModal();
          },
          complete: () => {
            this.spinner.hide().then();
            this.CerrarModal();
          }
        });
    }
    else {
      this.logger.notifica('Hay campos obligatorios.', 'error');
      if (this.isAdjunto) {
        this.CerrarModal();
      }
    }
  }

  TriggerFile(fileInput: HTMLElement, isApplication: boolean, isImage: boolean, isAudio: boolean, isVideo: boolean) {
    this.isApplication = isApplication;
    this.isVideo = isVideo;
    this.isAudio = isAudio;
    this.isImage = isImage;
    fileInput.click();
  }

  VerificarArchivoBase64(event: any, templateRef: TemplateRef<any>) {
    for (let c in this.dataFormGroup.controls) {
      this.dataFormGroup.controls[c].markAsTouched();
    }
    if (!this.dataFormGroup.valid) {
      this.logger.notifica('Hay campos obligatorios.', 'error');
      this.LimpiarFormularioArchivo();
      return;
    }
    let arrayArchivos: Array<string> = ['application', 'video', 'audio', 'image'];
    let tipo = (event.target.files[0].type).split('/');

    if (arrayArchivos.includes(tipo[0])) {
      this.isAdjunto = true;
      if (tipo[0] == TipoArchivosEnum.Archivo && this.isApplication) {
        let arrayDocumentos: Array<string> = ['pdf'];
        if (arrayDocumentos.includes(tipo[1])) {
          if (event.target.files[0].size < TamanioArchivosEnum.Documento) {
            this.ConvertirArchivoBase64(event, templateRef);
          }
          else {
            this.logger.notifica(`El documento adjunto sobrepasa la cantidad máxima permitida de ${TamanioArchivosEnum.Documento / 1000000} MB`, 'error');
            this.LimpiarFormularioArchivo();
          }
        }
        else {
          this.logger.notifica('Debe se permite documentos PDF', 'error');
          this.LimpiarFormularioArchivo();
        }
      }
      else if (tipo[0] == TipoArchivosEnum.Video && this.isVideo) {
        let arrayDocumentos: Array<string> = ['video'];
        if (arrayDocumentos.includes(tipo[0])) {
          if (event.target.files[0].size < TamanioArchivosEnum.Video) {
            this.ConvertirArchivoBase64(event, templateRef);
          }
          else {
            this.logger.notifica(`El documento adjunto sobrepasa la cantidad máxima permitida de ${TamanioArchivosEnum.Video / 1000000} MB`, 'error');
            this.LimpiarFormularioArchivo();
          }
        }
        else {
          this.logger.notifica('Debe se permite archivo del tipo video', 'error');
          this.LimpiarFormularioArchivo();
        }
      }
      else if (tipo[0] == TipoArchivosEnum.Audio && this.isAudio) {
        let arrayDocumentos: Array<string> = ['audio'];
        if (arrayDocumentos.includes(tipo[0])) {
          if (event.target.files[0].size < TamanioArchivosEnum.Audio) {
            this.ConvertirArchivoBase64(event, templateRef);
          }
          else {
            this.logger.notifica(`El documento adjunto sobrepasa la cantidad máxima permitida de ${TamanioArchivosEnum.Audio / 1000000} MB`, 'error');
            this.LimpiarFormularioArchivo();
          }
        }
        else {
          this.logger.notifica('Debe se permite archivo del tipo audio', 'error');
          this.LimpiarFormularioArchivo();
        }
      }
      else if (tipo[0] == TipoArchivosEnum.Imagen && this.isImage) {
        let arrayDocumentos: Array<string> = ['image'];
        if (arrayDocumentos.includes(tipo[0])) {
          if (event.target.files[0].size < TamanioArchivosEnum.Imagen) {
            this.ConvertirArchivoBase64(event, templateRef);
          }
          else {
            this.logger.notifica(`El documento adjunto sobrepasa la cantidad máxima permitida de ${TamanioArchivosEnum.Imagen / 1000000} MB`, 'error');
            this.LimpiarFormularioArchivo();
          }
        }
        else {
          this.logger.notifica('Debe se permite archivo del tipo imagen', 'error');
          this.LimpiarFormularioArchivo();
        }
      }
      else {
        this.logger.notifica('No se puede adjuntar el archivo.', 'error');
        this.LimpiarFormularioArchivo();
      }
    }
    else {
      this.logger.notifica('No se puede adjuntar el archivo, tiene que ser del tipo PDF, imagen, audio o video.', 'error');
      this.LimpiarFormularioArchivo();
    }
  }

  ConvertirArchivoBase64(event: any, templateRef: TemplateRef<any>) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      let splitBase64 = base64.split('base64,');
      this.mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
      this.mensajesArchivosNotificacionesDTO.numeroDestino = this.listaAsignacionConversacion.numeroCliente;
      this.mensajesArchivosNotificacionesDTO.numeroOrigen = this.numeroOrigen;
      //this.mensajesArchivosNotificacionesDTO.mensage = this.dataFormGroup.controls['inputMensaje'].value;
      this.mensajesArchivosNotificacionesDTO.adjuntoBase64 = splitBase64[1];
      this.mensajesArchivosNotificacionesDTO.nombreArchivo = event.target.files[0].name;
      this.mensajesArchivosNotificacionesDTO.mimeType = event.target.files[0].type;
      this.mensajesArchivosNotificacionesDTO.nroByte = event.target.files[0].size;

      this.archivoActual = base64;
      this.bsModalRef = this.modalService.show(templateRef, { backdrop: 'static', class: 'modal-lg' });
      this.EjecutarScript();
      // this.EnviarMensaje();
    };
  }

  FiltrarNumeros(e: any) {
    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 9) e.returnValue = false;
  }

  DevolverImagen(mimeType: string, base64: string) {
    return `data:${mimeType};base64,${base64}`;
  }

  DevolverAudio(mimeType: string, base64: string) {
    return `data:${mimeType};base64,${base64}`;
  }

  DevolverVideo(mimeType: string, base64: string) {
    return `data:${mimeType};base64,${base64}`;
  }

  DevolverMimeType(mime: string) {
    return `${mime}`;
  }


  DescargarArchivo(mimeType:string, base64: any, fileName: any) { 
    const src = `data:${mimeType};base64,${base64}`;// `${base64}`;
    console.log(src);
    const link = document.createElement("a")
    link.href = src
    link.download = fileName
    link.click()

    link.remove()
  }

  VerificarTexto(texto: string) {
    if (texto.includes('http')) {
      return `<a [href]="${texto}" target="_blank">${texto}</a>`
    }
    else {
      return texto
    }
  }

  EjecutarScript() {
    this.cargarScriptsService.CargarArchivoJS('components/base/lucide.js', 'id-base-lucide');
    this.cargarScriptsService.CargarArchivoJS('vendors/transition.js', 'id-transition');
    this.cargarScriptsService.CargarArchivoJS('pages/chat.js', 'id-chat');
    this.cargarScriptsService.CargarArchivoJS('components/base/tippy.js', 'id-base-tippy');
  }

  CerrarModal() {
    this.mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
    this.LimpiarFormulario();
    this.bsModalRef.hide();
  }

  LimpiarFormulario() {
    this.isAdjunto = false;
    this.isApplication = false;
    this.isImage = false;
    this.isVideo = false;
    this.isAudio = false;
    this.urlArchivo = '';
    this.dataFormGroup.reset();
  }

  LimpiarFormularioArchivo() {
    this.isAdjunto = false;
    this.isApplication = false;
    this.isImage = false;
    this.isVideo = false;
    this.isAudio = false;
    this.urlArchivo = '';
    this.mensajesArchivosNotificacionesDTO = new MensajeArchivosNotificacionesDTO();
    this.dataFormGroup.controls['inputFile'].reset();
  }

  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  /// Buscar por criterio
  /// </summary>
  BuscarPorCriterio() {

    let criterioBusqueda = new CriterioBusquedaObtenerDetalleDTO();
    let textoCriterioBusqueda = "";

    let nroCelular = this.dataFormGroupBusqueda.controls['inputNroCelular'].value;
    let fechaInicio = this.dataFormGroupBusqueda.controls['inputFechaInicio'].value;
    let fechaFin = this.dataFormGroupBusqueda.controls['inputFechaFin'].value;

    if (nroCelular != '' && nroCelular != null)
      criterioBusqueda.numeroCliente = '51' + nroCelular;

    if (fechaInicio != '' && fechaInicio != null) {
      criterioBusqueda.fechaInicio = moment(fechaInicio).format('YYYY-MM-DD');
      if (fechaFin == '' || fechaFin == null)
        criterioBusqueda.fechaFin = moment(fechaInicio).format('YYYY-MM-DD');
    }

    if (fechaFin != '' && fechaFin != null) {
      criterioBusqueda.fechaFin = moment(fechaFin).format('YYYY-MM-DD');
      if (fechaInicio == '' || fechaInicio == null)
        criterioBusqueda.fechaInicio = moment(fechaFin).format('YYYY-MM-DD');
    }

    new Map(Object.entries(criterioBusqueda)).forEach((value, key, map) => {
      textoCriterioBusqueda += `${key}=${value}&`;
    });
    textoCriterioBusqueda = textoCriterioBusqueda.substring(0, textoCriterioBusqueda.length - 1);
    if (textoCriterioBusqueda != "") {
      this.spinner.show().then();
      this.servicio.ObtenerDetalle(criterioBusqueda)
        .subscribe({
          next: (data: ListaObtenerDetalleDTO[]) => {
            if (data != null) {
              this.listaObtenerDetalle = data;
              console.log(this.listaObtenerDetalle);
            }
            else {
              this.logger.error('Error en la búsqueda, po favor revise los criterios si son correctos.');
            }
          },
          error: (e) => {
            console.log(e);
            if (e.status == '400') {
              this.logger.error('Los criterio ingresados no son válidos.');
            } else {
              this.logger.error('Error en la búsqueda');
            }
            this.spinner.hide().then();

          },
          complete: () => {
            this.spinner.hide().then();
          }
        });
    }
    else {
      this.logger.error('Debe ingresar por lo menos 1 criterio de búsqueda');
    }
  }

  EsPersonalClinica() {
    this.listadoAsignacionConversacion = [];
    let resultado = this.listadoAsignacionConversacionTemporal.filter(x => x.esPersonalClinica == true);
    resultado.forEach(element => {
      this.listadoAsignacionConversacion.push(element);
    });
  }

  EsCliente() {
    this.listadoAsignacionConversacion = [];
    let resultado = this.listadoAsignacionConversacionTemporal.filter(x => x.esPersonalClinica == false);
    resultado.forEach(element => {
      this.listadoAsignacionConversacion.push(element);
    });
  }

  TodosListado() {
    this.listadoAsignacionConversacion = [];
    this.listadoAsignacionConversacionTemporal.forEach(element => {
      this.listadoAsignacionConversacion.push(element);
    });
  }

  AbrirNuevoChat() {  
    this.bsModalRef = this.modalService.show(NuevoChatWhatsappComponent, { backdrop: 'static', class: 'modal-xl' });
    this.bsModalRef.content.onGuardar = () => {
      this.ObteneAsignacionConversacionPorIdPersonal(this.idPersonal);
    };
  }  

  ObtenerNumeroSesion() {  
    this.spinner.show().then();
    this.whatsAppService.ObtenerNumeroSesion()
      .subscribe({
        next: (data) => {
          console.log(data);   
          console.log(data.numero);
          this.numeroOrigen = data.numero;          
          this.ObteneAsignacionConversacionPorIdPersonal(this.idPersonal);          
        },
        error: (e) => {
          console.log(e);
          this.spinner.hide().then();
        },
        complete: () => { this.spinner.hide().then(); }
      });
   }

  ObteneAsignacionConversacionPorIdPersonal(idPersonal: number) {
    if(this.numeroOrigen == null) return;
    this.listadoAsignacionConversacion = [];
    this.listadoAsignacionConversacionTemporal = [];
    let criterioBusqueda = new CriterioBusquedaObtenerResumenDTO();
    criterioBusqueda.numeroCuenta = this.numeroOrigen;
    this.spinner.show().then();
    //this.servicio.ObtenerConversacionPorIdPersona(idPersonal)
    this.servicio.ObtenerResumen(criterioBusqueda)
      .subscribe({
        next: (data) => {
          console.log('ListadoAsignacion: ', data);
          data.forEach(element => {
            element.contador = 0;
            element.mensaje = '';
            this.listadoAsignacionConversacion.push(element);
            this.listadoAsignacionConversacionTemporal.push(element);
            this.EjecutarScript();
          });
          this.spinner.hide().then();
        },
        error: (e) => {
          console.log(e);
          this.spinner.hide().then();
        },
        complete: () => { this.spinner.hide().then(); }
      });
  }

  BuscarChat(e: any) {
    let arrayNumber = [8, 13, 16, 17, 18, 37, 38, 39, 40, 45, 46];
    let keyTexto = '';
    if (!arrayNumber.includes(e.keyCode)){
      keyTexto = (this.dataFormGroupBusqueda.controls['inputBuscarChat'].value).toLowerCase() + e.key.toLowerCase();
    }
    else{
      keyTexto = (this.dataFormGroupBusqueda.controls['inputBuscarChat'].value).toLowerCase();
    }       
    let filtered = [];
    if (keyTexto) {
      //filtered = this.listadoAsignacionConversacionTemporal.filter(x=>x.numeroCliente==keyTexto);
      filtered = this.listadoAsignacionConversacionTemporal.filter((el) => {
        return Object.values(el).some((val) =>
          String(val).toLowerCase().includes(keyTexto)
        );
      });
    }
    this.listadoAsignacionConversacion = filtered;
  }

  ObtenerConversacionPorIdDestinatario(lista: AsignacionConversacionDTO, verSpinner: boolean = false) {
    this.listaAsignacionConversacion = lista;
    this.destinatarioElegido = lista.destinatario;
    this.destinatarioFechaElegido = lista.fechaUltimaActualizacion;
    if (verSpinner) this.spinner.show().then();
    let criterioBusqueda = new CriterioBusquedaObtenerDetalleDTO();
    criterioBusqueda.numeroCliente = lista.numeroCliente;
    criterioBusqueda.numeroCuenta = this.numeroOrigen;
    //this.servicio.ObtenerUltimosMensajesPorIdDestinatario(lista.idDestinatario, this.numeroMensajes)
    this.servicio.ObtenerDetalle(criterioBusqueda)
      .subscribe({
        next: (data) => {
          this.verBotonesMultimedia = true;
          this.listadoMensajesDestinatario = [];
          //let ultimoMensaje = data[data.length - 1]
          data.forEach(element => {
            if (element.mimeType != '' && element.mimeType != null) {
              let splitMimeType = element.mimeType.split('/');
              if (splitMimeType[0] == "image") element.isImage = true;
              else if (splitMimeType[0] == "application") element.isDocument = true;
              else if (splitMimeType[0] == "video") element.isVideo = true;
              else if (splitMimeType[0] == "audio") element.isAudio = true;
            }
            else {
              element.isDocument = false;
              element.isImage = false;
              element.isVideo = false;
              element.isAudio = false;
            }
            element.idAsignacionAsignacionConversacion = lista.idAsignacionConversacion;
            this.listadoMensajesDestinatario.push(element);
            this.scrollToBottom();
          });
          console.log('listadoMensajesDestinatario: ', this.listadoMensajesDestinatario);
          let resultado = this.listadoMensajesDestinatario.find(x => x.idDestinatario == lista.idDestinatario);
          if (resultado != null) {
            this.idRemitente = resultado.idRemitente;
            this.idAsignacionConversacionActivo = resultado.idAsignacionAsignacionConversacion;

          }
          lista.contador = 0;
          lista.totalMensajesEntrantesSinLeer = 0;
          lista.mensaje = '';
          this.CerrarWaitChat();
          this.spinner.hide().then();
          //this.MarcarMensajesEntranteComoLeido(ultimoMensaje.idAsignacionAsignacionConversacion, ultimoMensaje.id);
          this.EjecutarScript();
        },
        error: (e) => {
          console.log(e);
          this.spinner.hide().then();
        },
        complete: () => { this.spinner.hide().then(); }
      });
  }  

  CerrarWaitChat() {
    let waitChat = <HTMLElement>document.querySelector('#wait-chat');
    waitChat.classList.add('hidden');
    let bodyChat = <HTMLElement>document.querySelector('#body-chat');
    bodyChat.classList.remove('hidden');
  }

  VerificarMensaje(tipo: number, lista: MensajeDestinatarioDTO) {
    let array = [TiposMensajesEnum.Entrante]
    let clase = 'float-right mb-4 flex max-w-[90%] items-end sm:max-w-[49%]';
    if (lista.idMensajeEntrante != null) clase = 'float-left mb-4 flex max-w-[90%] items-end sm:max-w-[49%]';
    //if (array.includes(tipo)) clase = 'float-left mb-4 flex max-w-[90%] items-end sm:max-w-[49%]';
    return clase;
  }

  VerificarFondoMensaje(tipo: number, lista: MensajeDestinatarioDTO) {
    let array = [TiposMensajesEnum.Entrante]
    let clase = 'rounded-l-md rounded-t-md bg-primary px-4 py-3 text-white';
    if (lista.idMensajeEntrante != null) clase = 'rounded-r-md rounded-t-md bg-slate-100 px-4 py-3 text-slate-500 dark:bg-darkmode-400';
    //if (array.includes(tipo)) clase = 'rounded-r-md rounded-t-md bg-slate-100 px-4 py-3 text-slate-500 dark:bg-darkmode-400';
    return clase;
  }

  VerificarFechaMensaje(tipo: number, lista: MensajeDestinatarioDTO) {
    let array = [TiposMensajesEnum.Entrante]
    let clase = 'mt-1 text-xs text-white text-opacity-80';
    if (lista.idMensajeEntrante != null) clase = 'mt-1 text-xs text-slate-500';
    //if (array.includes(tipo)) clase = 'mt-1 text-xs text-slate-500';
    return clase;
  }

  VerificarPosicionAvatar(tipo: number, lista: MensajeDestinatarioDTO) {
    let array = [TiposMensajesEnum.Entrante]
    let clase = true;
    if (lista.idMensajeEntrante != null) clase = false;
    //if (array.includes(tipo)) clase = false    
    return clase;
  }  
 
  
  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  /// Limpiar formulario
  /// </summary>
  LimpiarFormularioBusqueda() {
    this.listaObtenerDetalle = [];
    this.dataFormGroupBusqueda.reset();
  }

  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  ///  Permite resumir al control para el interfaz.
  /// </summary>
  get Controls() {
    return this.dataFormGroup.controls;
  }

  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  ///  Permite resumir al control para el interfaz.
  /// </summary>
  get ControlsBusqueda() {
    return this.dataFormGroupBusqueda.controls;
  }

  /// Autor: Fausto Danilo Esthela Espinoza
  /// Fecha: 15/04/2024
  /// Versión: 1.0
  /// <summary>
  /// Funciones para mostrar notificaciones.
  /// </summary>
  MostrarNotificacionSuccess(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'success',
      title: titulo,
      text: mensaje,
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
      }
    })
  }

  MostrarNotificacionEliminar(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: mensaje,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Confirmar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
      }
    })
  }

  MostrarNotificacionInfo(titulo: string, mensaje: string) {
    console.log('información');
    Swal.fire({
      icon: 'info',
      title: titulo,
      text: mensaje
    });
  }

  MostrarNotificacionWarning(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: mensaje
    });
  }

  MostrarNotificacionError(titulo: string, mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      html: `<div class="message-text-error">${mensaje} </div>`,
    });
  }

}

