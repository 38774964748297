import {Component} from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { locale, loadMessages } from "devextreme/localization";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Mensajería WhatsApp';

  private _hubConnection!: HubConnection;
  usuarioSignal: string = "";
  conectionId: string | null | undefined = '';

  constructor(
    private router: Router,
  ) {    
    this.usuarioSignal = 'administrador';  
    fetch('./dist/es.json').then(res => res.json())
    .then(esMessages => {     
     loadMessages(esMessages);   
    });
    locale(navigator.language);
  }

  ngOnInit(): void {
    
  }



  MostrarNotificacionSuccess(mensaje: string, titulo: string) {
    Swal.fire({
      icon: 'success',
      title: titulo,
      text: mensaje,
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('auth_token');
        this.router.navigate(['authenticated/Login']).then();
      }
    })
  }

}
