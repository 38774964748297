import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry,timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { LoginRequestDTO } from '../models/login-request';
import { IUsuario } from '../models/iusuario';

const API_URL = '';//environment.url;

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  helper = new JwtHelperService();
  public nTimeout:number=20000;
  public nRetry:number=0;
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {      
      console.error('A ocurrido un error :', error.error.message);
    } else {
      console.error(
        'El servidor retornó el código, ' +error.status);                
    }    
    return throwError(()=>error.error);
  };
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'    
  })

  datosUsuario:IUsuario = {
    idUsuario:'',
    idPersonal:'',
    usuario:'',
    nombres:'',
    apellidos:'',
    idRol:[],
  }
  constructor(private http: HttpClient) { }

  LoginUsuarios(data: LoginRequestDTO){
    let HTTPOptions:Object = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      }),
      responseType: 'text'
   }
    const url = `${API_URL}api/Autenticacion/Login`;
    return  this.http.post(url, data, HTTPOptions).pipe(
      map((response:any)=>{
        let token:string = response;
        const decodeToken = this.helper.decodeToken(response);
        this.datosUsuario.idUsuario = decodeToken.idUsuario;  
        this.datosUsuario.idPersonal = decodeToken.idPersonal;
        this.datosUsuario.usuario = decodeToken.usuario;  
        this.datosUsuario.nombres = decodeToken.nombres; 
        this.datosUsuario.apellidos = decodeToken.apellidos; 
        this.datosUsuario.idRol = decodeToken.idRol; 

        localStorage.setItem('auth_token', token);
        return this.datosUsuario;
      })
    );
  }

  LogIn(){
    const token:any = localStorage.getItem('auth_token');
    return !this.helper.isTokenExpired(token);
  }

  LogOut(){
    localStorage.removeItem('auth_token');
  }
}
