<!-- BEGIN: Top Bar -->
<div class="relative z-[51] flex h-[67px] items-center border-b border-slate-200">
    <!-- BEGIN: Breadcrumb -->
    <nav aria-label="breadcrumb" class="flex -intro-x mr-auto hidden sm:flex">
        <ol class="flex items-center text-theme-1 dark:text-slate-300">
            
        </ol>
    </nav>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <div class="search intro-x relative mr-3 sm:mr-6">
    </div>
    <!-- END: Search  -->
    <!-- BEGIN: Notifications -->
    <!--
    <div data-tw-merge="" data-tw-placement="bottom-end"
        class="dropdown relative intro-x mr-auto sm:mr-6">
        <div data-tw-toggle="dropdown" aria-expanded="false"
            class="cursor-pointer relative block text-slate-600 outline-none before:absolute before:right-0 before:top-[-2px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-danger before:content-['']">
            <i data-tw-merge="" data-lucide="bell" class="stroke-1.5 w-5 h-5 dark:text-slate-500"></i>
        </div>
        <div data-transition="" data-selector=".show"
            data-enter="transition-all ease-linear duration-150"
            data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
            data-enter-to="!mt-1 visible opacity-100 translate-y-0"
            data-leave="transition-all ease-linear duration-150"
            data-leave-from="!mt-1 visible opacity-100 translate-y-0"
            data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
            class="dropdown-menu absolute z-[9999] hidden">
            <div data-tw-merge=""
                class="dropdown-content rounded-md border-transparent bg-white shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 mt-2 w-[280px] p-5 sm:w-[350px]">
                <div class="mb-5 font-medium">Notifications</div>
                <div class="cursor-pointer relative flex items-center">
                    <div class="image-fit relative mr-1 h-12 w-12 flex-none">
                        <img class="rounded-full" src="./dist/images/fakers/no-avatar.jpg"
                            alt="Midone - Tailwind Admin Dashboard Template">
                        <div
                            class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600">
                        </div>
                    </div>
                    <div class="ml-2 overflow-hidden">
                        <div class="flex items-center">
                            <a class="mr-5 truncate font-medium" href="">
                                Arnold Schwarzenegger
                            </a>
                            <div class="ml-auto whitespace-nowrap text-xs text-slate-400">
                                06:05 AM
                            </div>
                        </div>
                        <div class="mt-0.5 w-full truncate text-slate-500">
                            There are many variations of passages of Lorem Ipsum available, but the
                            majority have suffered alteration in some form, by injected humour, or
                            randomi
                        </div>
                    </div>
                </div>
                <div class="cursor-pointer relative flex items-center mt-5">
                    <div class="image-fit relative mr-1 h-12 w-12 flex-none">
                        <img class="rounded-full" src="./dist/images/fakers/no-avatar.jpg"
                            alt="Midone - Tailwind Admin Dashboard Template">
                        <div
                            class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600">
                        </div>
                    </div>
                    <div class="ml-2 overflow-hidden">
                        <div class="flex items-center">
                            <a class="mr-5 truncate font-medium" href="">
                                Johnny Depp
                            </a>
                            <div class="ml-auto whitespace-nowrap text-xs text-slate-400">
                                01:10 PM
                            </div>
                        </div>
                        <div class="mt-0.5 w-full truncate text-slate-500">
                            There are many variations of passages of Lorem Ipsum available, but the
                            majority have suffered alteration in some form, by injected humour, or
                            randomi
                        </div>
                    </div>
                </div>
                <div class="cursor-pointer relative flex items-center mt-5">
                    <div class="image-fit relative mr-1 h-12 w-12 flex-none">
                        <img class="rounded-full" src="./dist/images/fakers/no-avatar.jpg"
                            alt="Midone - Tailwind Admin Dashboard Template">
                        <div
                            class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600">
                        </div>
                    </div>
                    <div class="ml-2 overflow-hidden">
                        <div class="flex items-center">
                            <a class="mr-5 truncate font-medium" href="">
                                Johnny Depp
                            </a>
                            <div class="ml-auto whitespace-nowrap text-xs text-slate-400">
                                05:09 AM
                            </div>
                        </div>
                        <div class="mt-0.5 w-full truncate text-slate-500">
                            There are many variations of passages of Lorem Ipsum available, but the
                            majority have suffered alteration in some form, by injected humour, or
                            randomi
                        </div>
                    </div>
                </div>
                <div class="cursor-pointer relative flex items-center mt-5">
                    <div class="image-fit relative mr-1 h-12 w-12 flex-none">
                        <img class="rounded-full" src="./dist/images/fakers/no-avatar.jpg"
                            alt="Midone - Tailwind Admin Dashboard Template">
                        <div
                            class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600">
                        </div>
                    </div>
                    <div class="ml-2 overflow-hidden">
                        <div class="flex items-center">
                            <a class="mr-5 truncate font-medium" href="">
                                Johnny Depp
                            </a>
                            <div class="ml-auto whitespace-nowrap text-xs text-slate-400">
                                01:10 PM
                            </div>
                        </div>
                        <div class="mt-0.5 w-full truncate text-slate-500">
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has
                            roots in a piece of classical Latin literature from 45 BC, making it over 20
                        </div>
                    </div>
                </div>
                <div class="cursor-pointer relative flex items-center mt-5">
                    <div class="image-fit relative mr-1 h-12 w-12 flex-none">
                        <img class="rounded-full" src="./dist/images/fakers/no-avatar.jpg"
                            alt="Midone - Tailwind Admin Dashboard Template">
                        <div
                            class="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600">
                        </div>
                    </div>
                    <div class="ml-2 overflow-hidden">
                        <div class="flex items-center">
                            <a class="mr-5 truncate font-medium" href="">
                                Russell Crowe
                            </a>
                            <div class="ml-auto whitespace-nowrap text-xs text-slate-400">
                                01:10 PM
                            </div>
                        </div>
                        <div class="mt-0.5 w-full truncate text-slate-500">
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has
                            roots in a piece of classical Latin literature from 45 BC, making it over 20
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
-->
    <!-- END: Notifications  -->
    <!-- BEGIN: Account Menu -->
    <div data-tw-merge="" data-tw-placement="bottom-end" class="dropdown relative"><button
            data-tw-toggle="dropdown" aria-expanded="false"
            class="cursor-pointer image-fit zoom-in intro-x block h-8 w-8 overflow-hidden rounded-full shadow-lg"><img
                src="./dist/images/fakers/no-avatar.jpg"
                alt="Midone - Tailwind Admin Dashboard Template">
        </button>
        <div data-transition="" data-selector=".show"
            data-enter="transition-all ease-linear duration-150"
            data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
            data-enter-to="!mt-1 visible opacity-100 translate-y-0"
            data-leave="transition-all ease-linear duration-150"
            data-leave-from="!mt-1 visible opacity-100 translate-y-0"
            data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
            class="dropdown-menu absolute z-[9999] hidden">
            <div data-tw-merge=""
                class="dropdown-content rounded-md border-transparent p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 mt-px w-56 bg-theme-1 text-white">
                <div class="p-2 font-medium font-normal">
                    <div class="font-medium">Usuario</div>
                    <div class="mt-0.5 text-xs text-white/70 dark:text-slate-500">
                        Administrador
                    </div>
                </div>
                <div class="h-px my-2 -mx-2 bg-slate-200/60 dark:bg-darkmode-400 bg-white/[0.08]">
                </div>
                <a routerLink="/login" (click)="CerrarToolBar()"
                    class="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item hover:bg-white/5"><i
                        data-tw-merge="" data-lucide="toggle-right" class="stroke-1.5 mr-2 h-4 w-4"></i>
                    Logout</a>
            </div>
        </div>
    </div>
    <!-- END: Account Menu -->
</div>
<!-- END: Top Bar -->