import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry,timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
//const API_URL = environment.apiURLPrincipal;
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  helper = new JwtHelperService();
  public nTimeout:number=20000;
  public nRetry:number=0;
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {      
      console.error('A ocurrido un error :', error.error.message);
    } else {
      console.error(
        'El servidor retornó el código, ' +error.status);                
    }    
    return throwError(()=>error.error);
  };
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'    
  })

  constructor(private http: HttpClient,private router: Router) { }

  LogIn(){
    const token:any = localStorage.getItem('auth_token');
    return !this.helper.isTokenExpired(token);
  }

  LogOut(){
    localStorage.removeItem('auth_token');
    this.router.navigate(['/signin']).then();
   // window.location.href = API_URL;
  }
}
