<div class="container-fluid">
  <div class="col">
    <div class="col text-center">
      <h2 class="mt-3 mb-3 text-2xl font-medium leading-none text-success">MENSAJERIA WHATSAPP</h2>
    </div>
    <div class="col text-center">
      <div style="margin-right: auto;margin-left: auto;" class="col border round-container p-3">
        <div style="margin-right: auto;margin-left: auto;" class="row col-sm-12">
          <div (click)="envioGeneracionQRClick()" class="card card-app text-center my-4" >
            <div class="card-body">
              <div class="app-card-icon">
                <div class="img-card">
                  <img src="assets/generacion-qr.png" alt="icon-1" class="py-3 icons">
                </div>
              </div>
              <h3>Escanear QR</h3>
              <p class="card-description py-3 ">
                Escanear QR para iniciar sesión.
              </p>
            </div>
          </div>
          <div (click)="envioNotificacionesClick()" class="card card-app text-center my-4" >
            <div class="card-body">
              <div class="app-card-icon">
                <div class="img-card">
                  <img src="assets/notificaciones.png" alt="icon-1" class="py-3 icons">
                </div>
              </div>
              <h3>Notificaciones</h3>
              <p class="card-description pt-3">
                Envío de notificaciones por WhatsApp.
              </p>
            </div>
          </div>
          <div (click)="envioContenidoClick()" class="card card-app text-center my-4" >
            <div class="card-body">
              <div class="app-card-icon">
                <div class="img-card">
                  <img src="assets/chat-whatsapp.png" alt="icon-1" class="py-3 icons">
                </div>
              </div>
              <h3>Env&iacute;o de contenido</h3>
              <p class="card-description pt-3">
                Envío de mensajes por WhatsApp.
              </p>
            </div>
          </div>         
        </div>
      </div>
    </div>