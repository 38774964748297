<div data-tw-merge="" [class]="styleDivMainModal">
  <a [class]="styleAX" (click)="CerrarModal()" href="javascript:;">
    <i data-tw-merge data-lucide="x" [class]="styleIconX"></i>
  </a>
  <div [class]="styleDivHeaderModal">
    <h2 class="mr-auto text-base font-medium">
      NUEVO CHAT
    </h2>
    <div data-tw-merge="" data-tw-placement="bottom-end" class="dropdown relative sm:hidden"><button
        data-tw-toggle="dropdown" aria-expanded="false" class="cursor-pointer block h-5 w-5"><i data-tw-merge=""
          data-lucide="more-horizontal" class="stroke-1.5 w-5 h-5 text-slate-500"></i>
      </button>
    </div>
  </div>
  <form class="w-full form" autocomplete="off" novalidate="novalidate" [formGroup]="dataFormGroup">
    <div class="p-5 grid grid-cols-7 gap-5">
      <div class="col-span-12">
        <div data-tw-merge inputGroup="inputGroup" class="flex group input-group">
          <div data-tw-merge
            class="py-2 px-3 bg-slate-100 border shadow-sm border-slate-200 text-slate-600 dark:bg-darkmode-900/20 dark:border-darkmode-900/20 dark:text-slate-400 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r">
            +51
          </div>
          <input data-tw-merge="" placeholder="Escriba número destino" [class]="styleInputText"
            formControlName="inputNumeroDestino" (keypress)="FiltrarNumeros($event)" maxlength="9">
        </div>
        <div
          *ngIf="Controls['inputNumeroDestino'].invalid && (Controls['inputNumeroDestino'].dirty || Controls['inputNumeroDestino'].touched)"
          class="text-error">
          <div>Campo obligatorio.</div>
        </div>
      </div>
      <div class="col-span-12 text-center">
        <button data-tw-merge="" data-placement="top" title="Enviar archivo"
          [class]="styleListSmallButtonRoundedPrimary" class="rounded-full"
          (click)="TriggerFile(fileInput,true,false,false,false)"><i data-tw-merge="" data-lucide="file-text"
            [class]="styleIconButtonSmall"></i></button>
        &nbsp;&nbsp;

        <button data-tw-merge="" data-placement="top" title="Enviar imagen" [class]="styleListSmallButtonRoundedPrimary"
          class="rounded-full" (click)="TriggerFile(fileInput,false,true,false,false)"><i data-tw-merge=""
            data-lucide="image" [class]="styleIconButtonSmall"></i></button>
        &nbsp;&nbsp;
        <button data-tw-merge="" data-placement="top" title="Enviar audio" [class]="styleListSmallButtonRoundedPrimary"
          class="rounded-full" (click)="TriggerFile(fileInput,false,false,true,false)"><i data-tw-merge=""
            data-lucide="music" [class]="styleIconButtonSmall"></i></button>
        &nbsp;&nbsp;

        <button data-tw-merge="" data-placement="top" title="Enviar video" [class]="styleListSmallButtonRoundedPrimary"
          class="rounded-full" (click)="TriggerFile(fileInput,false,false,false,true)"><i data-tw-merge=""
            data-lucide="video" [class]="styleIconButtonSmall"></i></button>
       
      </div>
      <div class="col-span-11">
        <textarea autosize data-tw-merge="" rows="1" placeholder="Escriba su mensaje" [class]="styleTextareaChat"
          formControlName="inputMensaje" (keydown.control.enter)="EnviarMensaje()"></textarea>
        <input hidden (change)="VerificarArchivoBase64($event, MyFile)" #fileInput type="file"
          formControlName="inputFile">
        <div
          *ngIf="Controls['inputMensaje'].invalid && (Controls['inputMensaje'].dirty || Controls['inputMensaje'].touched)"
          class="text-error">
          <div>Campo obligatorio.</div>
        </div>
      </div>
      <div class="flex items-end">
        <!--<button data-tw-merge="" data-placement="top" title="Adjuntar archivo"
        [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="fileInput.click()"><i
        data-tw-merge="" data-lucide="paperclip" [class]="styleIconButtonSmall"></i></button>
    &nbsp;&nbsp;
    -->
        <button data-tw-merge="" data-placement="top" title="Enviar mensaje"
          [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="EnviarMensaje()"><i
            data-tw-merge="" data-lucide="send" [class]="styleIconButtonSmall"></i></button>
      </div>
    </div>


    <ng-template #MyFile>
      <div data-tw-merge="">
        <a [class]="styleAX" (click)="CerrarModal()" href="javascript:;">
          <i data-tw-merge data-lucide="x" [class]="styleIconX"></i>
        </a>
        <div [class]="styleDivHeaderModal">
          <h2 class="mr-auto text-base font-medium">
            ARCHIVO ADJUNTO
          </h2>
        </div>
        <div data-tw-merge="" class="p-8 grid grid-cols-12 gap-4 gap-y-3">
          <div *ngIf="isApplication" class="col-span-12">
            <div class="boton-download-file flex justify-center">
              <img src="../../assets/text-document.svg" class="width-50" />
            </div>
          </div>
          <div *ngIf="isImage" class="col-span-12">
            <img [src]="archivoActual" class="image width-100" />
          </div>
          <div *ngIf="isAudio" class="col-span-12 flex justify-center">
            <audio controls>
              <source [src]="archivoActual">
            </audio>
          </div>
          <div *ngIf="isVideo" class="col-span-12 flex justify-center">
            <video controls class="width-50">
              <source [src]="archivoActual">
            </video>
          </div>
          <!--<div class="col-span-12 mb-3 mt-3">
        <strong>El tamaño máximo del archivo es {{tamanioImage}}
            MB.</strong>
        </div>-->
          <div class="col-span-11">
            <textarea autosize data-tw-merge="" rows="1" placeholder="Escriba su mensaje" [class]="styleTextareaChat"
              formControlName="inputMensaje" (keydown.control.enter)="EnviarMensaje()"></textarea>
          </div>
          <div class="flex items-end">
            <button data-tw-merge="" data-placement="top" title="Enviar mensaje"
              [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="EnviarMensaje()"><i
                data-tw-merge="" data-lucide="send" [class]="styleIconButtonSmall"></i></button>
          </div>
        </div>
        <!--<div class="py-3 text-right border-t border-slate-200/60 dark:border-darkmode-400">
        <button data-tw-merge (click)="CerrarModal()" data-placement="top" title="Cerrar ventana" [class]="styleButtonDanger"> <i data-tw-merge
        data-lucide="x" [class]="styleIconButtonText"></i>
        Cerrar</button>
    </div>-->
      </div>
    </ng-template>

  </form>
  <div class="px-5 py-3 text-right border-t border-slate-200/60 dark:border-darkmode-400">

    <button data-tw-merge (click)="CerrarModal()" [class]="styleButtonDanger"><i data-tw-merge data-lucide="x"
        [class]="styleIconButtonText"></i>
      Cerrar</button>    
  </div>
</div>
