export class AsignacionConversacionDTO{
    idAsignacionConversacion:number;
    idDestinatario:number;
    destinatario:string;
    numeroTelefonoDestinatario:string;
    idPersonal:number;
    fechaUltimaActualizacion:Date; 
    totalMensajes:number;
    totalMensajesEntrantesSinLeer:number;
    esPersonalClinica:boolean;
    contador:number = 0; 
    mensaje:string; 
    timer:number = 0;  
    fechaUltimoMensaje:Date;
    numeroCliente:string;
}