import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, Observable, of, map } from "rxjs";
import { environment } from '../../environments/environment';
import { MensajeArchivosNotificacionesDTO } from '../models/mensajes-archivos-notificaciones-dto';
import { CriterioBusquedaObtenerDetalleDTO } from '../models/criterio-busqueda-obtener-detalle-dto';
import { CriterioBusquedaObtenerResumenDTO } from '../models/criterio-busqueda-obtener-resumen-dto';
import { LoggerService } from './logger.service';



@Injectable({
  providedIn: 'root'
})
export class ServiciosService {
  
  apiNotificacionesEndPoint: string;
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  })
  constructor(private _http: HttpClient,
    private logger: LoggerService) {    
    this.apiNotificacionesEndPoint = environment.urlWhatsAppNotificacion;
  }
  
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.statusText !== undefined) {
        if (error.statusText === "Unknown Error") {
          this.logger.error('No se ha podido conectar con el servidor, revisar la conexion a Internet');
        }
      } else {
        this.logger.error(`${operation} failed: ${error.error}`);
      }
      return of(result as T);
    };
  }


  //#region Whatsapp

  EnviarNotificacion(data: MensajeArchivosNotificacionesDTO) {
    const url = `${this.apiNotificacionesEndPoint}api/WhatsApp/agendar`;
    return this._http.post(url, data, { headers: this.headers }).pipe(catchError(this.handleError<MensajeArchivosNotificacionesDTO>('GuardarNotificacion', null)));
  }

  ObtenerDetalle(data: CriterioBusquedaObtenerDetalleDTO) {
    const url = `${this.apiNotificacionesEndPoint}api/Conversacion/ObtenerDetalle`;
    return this._http.post(url, data, { headers: this.headers }).pipe(
      map((response:any)=>{        
        return response;
      }),
      catchError(this.handleError<CriterioBusquedaObtenerDetalleDTO>('ObtenerDetalle', null))
    );
  }
  ObtenerResumen(data:CriterioBusquedaObtenerResumenDTO) {
    const url = `${this.apiNotificacionesEndPoint}api/Conversacion/ObtenerResumen`;
    return this._http.post(url, data, { headers: this.headers }).pipe(
      map((response:any)=>{        
        return response;
      }),
      catchError(this.handleError<CriterioBusquedaObtenerResumenDTO>('ObtenerResumen', null))
    );
  }
 
  //#endregion

}
