import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ToastrModule} from "ngx-toastr";
import { MaterialModule } from './material.module';
import {DxCalendarModule,DxDataGridModule,DxDateBoxModule,DxLoadPanelModule,DxSelectBoxModule,DxToastModule} from "devextreme-angular";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxSpinnerModule} from "ngx-spinner";
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthGuard} from "./services/auth-guard.service";
import {HotTableModule} from "@handsontable/angular";
import { DevexpressModule } from './devexpress.module';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { LoginComponent } from './auth/login/login.component';
import { SiderbarComponent } from './shared/siderbar/siderbar.component';
import { SiderbarMobileComponent } from './shared/siderbar-mobile/siderbar-mobile.component';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import { PagesComponent } from './pages/pages.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AutosizeModule } from 'ngx-autosize';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { RecepcionarUsuarioComponent } from './recepcionar-usuario/recepcionar-usuario.component';
import { ChatWhatsappComponent } from './chat-whatsapp/chat-whatsapp.component';
import { NuevoChatWhatsappComponent } from './chat-whatsapp/nuevo-chat-whatsapp/nuevo-chat-whatsapp.component';

// import {GlobalErrorHandler} from "./interceptors/error-handler";

interface NgxSpinnerConfig {
  type?: string;
}

@NgModule({
  declarations: [
    AppComponent,    
    DashboardComponent,             
    LoginComponent,
    SiderbarComponent,
    SiderbarMobileComponent,
    TopBarComponent,
    PagesComponent,
    NotificacionesComponent,
    RecepcionarUsuarioComponent,
    ChatWhatsappComponent,
    NuevoChatWhatsappComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    FontAwesomeModule,
    DxToastModule,
    DxLoadPanelModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    NgbModule,    
    NgxSpinnerModule, 
    BrowserAnimationsModule,    
    ToastrModule.forRoot({
      progressBar: true
    }),
    HotTableModule,    
    DxDataGridModule,    
    MaterialModule,
    DxCalendarModule,
    DevexpressModule,
    ModalModule.forRoot(), 
    AutosizeModule
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [        
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule {
}
