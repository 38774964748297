import { Component, OnInit } from '@angular/core';
import { TiposRolesEnum } from 'src/app/enum/tipos-roles';
import { SettingsService } from 'src/app/auth/settings/settings.service';

@Component({
  selector: 'app-siderbar',
  templateUrl: './siderbar.component.html',
  styleUrls: ['./siderbar.component.scss']
})
export class SiderbarComponent implements OnInit {
  tituloMenu: string = 'dashboard';
  tituloSubMenu: string = '';
  esAdministrador: boolean = false;
  esSupervisor: boolean = false;
  esEjecutivo: boolean = false;

  constructor(
    public settings: SettingsService,
  ) {
  }
  ngOnInit(): void {
    let idRol = this.settings.getUserSetting('idRol');
    if(idRol == TiposRolesEnum.Administrador) {
      this.esAdministrador = true;
      this.esSupervisor = false;
      this.esEjecutivo = false;
    }
    else if(idRol == TiposRolesEnum.Supervisor) {
      this.esAdministrador = false;
      this.esSupervisor = true;
      this.esEjecutivo = false;
    }
    else if(idRol == TiposRolesEnum.Ejecutivo) {
      this.esAdministrador = false;
      this.esSupervisor = false;
      this.esEjecutivo = true;
    }
  }

  AsignarTituloMenu(titulo: string, tituloSubMenu: string) {    
    this.tituloMenu = titulo;
    this.tituloSubMenu = tituloSubMenu;    
  }
}
