<div class="intro-y mt-2 flex flex-col items-center sm:flex-row">
  <div class="flex mr-2 w-full sm:mt-0 sm:w-auto">
    <button data-tw-merge="" data-placement="top" title="Regresar" [class]="styleListSmallButtonRoundedSecondary"
      class="rounded-full tooltip" (click)="Atras()"><i data-tw-merge="" data-lucide="arrow-left"
        [class]="styleIconButtonSmall"></i></button>
  </div>    
  <h2 class="mr-auto text-lg font-medium">NOTIFICACIONES</h2>  
</div>
<div class="grid grid-cols-12 gap-5">
  <div class="col-span-12 lg:col-span-2"></div>
  <!-- BEGIN: Chat Content -->
  <div class="col-span-12 lg:col-span-8">
    <div class="chat-box box">
      <!-- BEGIN: Chat Active -->
      <div class="flex items-center border-t border-slate-200/60 pb-10 pt-4 dark:border-darkmode-400 sm:py-4">
        <form class="w-full form" autocomplete="off" novalidate="novalidate" [formGroup]="dataFormGroup">
          <div class="p-5 grid grid-cols-7 gap-5">
            <div class="col-span-12">
              <div data-tw-merge inputGroup="inputGroup" class="flex group input-group">
                <div data-tw-merge 
                  class="py-2 px-3 bg-slate-100 border shadow-sm border-slate-200 text-slate-600 dark:bg-darkmode-900/20 dark:border-darkmode-900/20 dark:text-slate-400 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r">
                  +51
                </div>
                <input data-tw-merge="" placeholder="Escriba número destino" [class]="styleInputText"
                  formControlName="inputNumeroDestino" (keypress)="FiltrarNumeros($event)" maxlength="9">
              </div>
              <div
                *ngIf="Controls['inputNumeroDestino'].invalid && (Controls['inputNumeroDestino'].dirty || Controls['inputNumeroDestino'].touched)"
                class="text-error">
                <div>Campo obligatorio.</div>
              </div>
            </div>
            <div class="col-span-12 text-center">
              <button data-tw-merge="" data-placement="top" title="Enviar archivo"
                [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="TriggerFile(fileInput,true,false,false,false)"><i
                  data-tw-merge="" data-lucide="file-text" [class]="styleIconButtonSmall"></i></button>
              &nbsp;&nbsp;             
  
              <button data-tw-merge="" data-placement="top" title="Enviar imagen"
                [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="TriggerFile(fileInput,false,true,false,false)"><i
                  data-tw-merge="" data-lucide="image" [class]="styleIconButtonSmall"></i></button>
              &nbsp;&nbsp;                          
              <button data-tw-merge="" data-placement="top" title="Enviar audio"
                [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="TriggerFile(fileInput,false,false,true,false)"><i
                  data-tw-merge="" data-lucide="music" [class]="styleIconButtonSmall"></i></button>
              &nbsp;&nbsp;              
  
              <button data-tw-merge="" data-placement="top" title="Enviar video"
                [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="TriggerFile(fileInput,false,false,false,true)"><i
                  data-tw-merge="" data-lucide="video" [class]="styleIconButtonSmall"></i></button>              
            </div>
            <div class="col-span-11">
              <textarea autosize data-tw-merge="" rows="1" placeholder="Escriba su mensaje" [class]="styleTextareaChat"
                formControlName="inputMensaje" (keydown.control.enter)="EnviarMensaje()"></textarea>
                <input hidden (change)="VerificarArchivoBase64($event, MyFile)" #fileInput type="file"  formControlName="inputFile">
              <div
                *ngIf="Controls['inputMensaje'].invalid && (Controls['inputMensaje'].dirty || Controls['inputMensaje'].touched)"
                class="text-error">
                <div>Campo obligatorio.</div>
              </div>
            </div>
            <div class="flex items-end">
              <!--<button data-tw-merge="" data-placement="top" title="Adjuntar archivo"
                [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="fileInput.click()"><i
                  data-tw-merge="" data-lucide="paperclip" [class]="styleIconButtonSmall"></i></button>
              &nbsp;&nbsp;
            -->
              <button data-tw-merge="" data-placement="top" title="Enviar mensaje"
                [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="EnviarMensaje()"><i
                  data-tw-merge="" data-lucide="send" [class]="styleIconButtonSmall"></i></button>
            </div>
          </div>


          <ng-template #MyFile>
            <div data-tw-merge="">
              <a [class]="styleAX" (click)="CerrarModal()" href="javascript:;">
                <i data-tw-merge data-lucide="x" [class]="styleIconX"></i>
              </a>
              <div [class]="styleDivHeaderModal">
                <h2 class="mr-auto text-base font-medium">
                  ARCHIVO ADJUNTO
                </h2>
              </div>
              <div data-tw-merge="" class="p-8 grid grid-cols-12 gap-4 gap-y-3">
                <div *ngIf="isApplication" class="col-span-12">
                  <div class="boton-download-file flex justify-center">
                    <img src="../../assets/text-document.svg" class="width-50" />
                  </div>
                </div>
                <div *ngIf="isImage" class="col-span-12">
                  <img [src]="archivoActual" class="image width-100" />
                </div>
                <div *ngIf="isAudio" class="col-span-12 flex justify-center">
                  <audio controls>
                    <source [src]="archivoActual">
                  </audio>
                </div>
                <div *ngIf="isVideo" class="col-span-12 flex justify-center">
                  <video controls class="width-50">
                    <source [src]="archivoActual">
                  </video>
                </div>
                <!--<div class="col-span-12 mb-3 mt-3">
                  <strong>El tamaño máximo del archivo es {{tamanioImage}}
                    MB.</strong>
                </div>-->                
                <div class="col-span-11">
                  <textarea autosize data-tw-merge="" rows="1" placeholder="Escriba su mensaje" [class]="styleTextareaChat"
                    formControlName="inputMensaje" (keydown.control.enter)="EnviarMensaje()"></textarea>
                </div>
                <div class="flex items-end">                  
                  <button data-tw-merge="" data-placement="top" title="Enviar mensaje"
                    [class]="styleListSmallButtonRoundedPrimary" class="rounded-full" (click)="EnviarMensaje()"><i
                      data-tw-merge="" data-lucide="send" [class]="styleIconButtonSmall"></i></button>
                </div>
              </div>
              <!--<div class="py-3 text-right border-t border-slate-200/60 dark:border-darkmode-400">
                <button data-tw-merge (click)="CerrarModal()" data-placement="top" title="Cerrar ventana" [class]="styleButtonDanger"> <i data-tw-merge
                  data-lucide="x" [class]="styleIconButtonText"></i>
                  Cerrar</button>
              </div>-->
            </div>
          </ng-template>

        </form>
      </div>
    </div>
    <!-- END: Chat Active -->
  </div>
  <div class="col-span-12 lg:col-span-2"></div>
</div>
<!-- END: Chat Content -->

 <!-- BEGIN: Basic Card -->
 <div class="mt-2 preview-component intro-y box"> 
  <div class="p-5">    
    <!-- BEGIN: Data List -->
    <form class="form" autocomplete="off" novalidate="novalidate" [formGroup]="dataFormGroupBusqueda">
      <div data-tw-merge="" class="p-5 grid grid-cols-4 gap-4 gap-y-3">
        <div class="col-span-12 sm:col-span-1 lg:col-span-1">
          <label data-tw-merge="" [class]="styleLabel">
            Nro. celular:
          </label>
          <div data-tw-merge inputGroup="inputGroup" class="flex group input-group">
            <div data-tw-merge 
              class="py-2 px-3 bg-slate-100 border shadow-sm border-slate-200 text-slate-600 dark:bg-darkmode-900/20 dark:border-darkmode-900/20 dark:text-slate-400 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r">
              +51
            </div>
            <input data-tw-merge="" type="text" placeholder="Ingrese nro. celular." [class]="styleInputText"
              formControlName="inputNroCelular"  (keypress)="FiltrarNumeros($event)" maxlength="9">
          </div>
          
          <div
            *ngIf="ControlsBusqueda['inputNroCelular'].invalid && (ControlsBusqueda['inputNroCelular'].dirty || ControlsBusqueda['inputNroCelular'].touched)"
            class="text-error">
            <div>Campo obligatorio.</div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-1 lg:col-span-1">
          <label data-tw-merge="" [class]="styleLabel">
            Fecha inicio: 
          </label>
          <dx-date-box placeholder="dd/mm/aaaa" [showClearButton]="true" [useMaskBehavior]="true"
            displayFormat="shortdate" type="date" formControlName="inputFechaInicio" [classList]="styleInputText">
          </dx-date-box>
          <div
            *ngIf="ControlsBusqueda['inputFechaInicio'].invalid && (ControlsBusqueda['inputFechaInicio'].dirty || ControlsBusqueda['inputFechaInicio'].touched)"
            class="text-error">
            <div>Campo obligatorio.</div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-1 lg:col-span-1">
          <label data-tw-merge="" [class]="styleLabel">
            Fecha fin: 
          </label>
          <dx-date-box placeholder="dd/mm/aaaa" [showClearButton]="true" [useMaskBehavior]="true"
            displayFormat="shortdate" type="date" formControlName="inputFechaFin" [classList]="styleInputText">
          </dx-date-box>
          <div
            *ngIf="ControlsBusqueda['inputFechaFin'].invalid && (ControlsBusqueda['inputFechaFin'].dirty || ControlsBusqueda['inputFechaFin'].touched)"
            class="text-error">
            <div>Campo obligatorio.</div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-1 lg:col-span-1">
          <label class="inline-block mb-2"> &nbsp;
          </label>
          <br />
          <button data-tw-merge [class]="styleButtonSearch" (click)="BuscarPorCriterio()"><i data-tw-merge
              data-lucide="search" [class]="styleIconButtonText"></i>
            Buscar</button>
          <button data-tw-merge [class]="styleButtonDanger" (click)="LimpiarFormularioBusqueda()"><i data-tw-merge
              data-lucide="eraser" [class]="styleIconButtonText"></i>
            Limpiar</button>
        </div>
      </div>
    </form>
    <div class="intro-y col-span-12 overflow-auto sm:overflow-visible">
      <dx-data-grid id="gridContainer" [dataSource]="listaObtenerDetalle" [showBorders]="true"
        [remoteOperations]="true">        
        <dxi-column dataField="numeroCliente" caption="Nro. cliente"  dataType="string"> </dxi-column>
        <dxi-column dataField="fechaEnvio" dataType="date"> </dxi-column>        
        <dxi-column dataField="mensaje" dataType="string"> </dxi-column>        
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[8, 12, 20]"></dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
      </dx-data-grid>
    </div>
    <!-- END: Data List -->
  </div>
</div>
<!-- END: Basic Card -->

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Espere un momento por favor... </p>
</ngx-spinner>
