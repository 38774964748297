export enum TamanioArchivosEnum {
    Audio = 16000000,
    Documento = 100000000,
    Imagen = 5000000,    
    Video = 16000000
}
export enum TipoArchivosEnum {
    Audio = 'audio',
    Archivo = 'application',
    Imagen = 'image',    
    Video = 'video'
}
