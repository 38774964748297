import {
  Injectable
} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EstilosService {
  styleDivMainModal = 'mx-auto bg-white relative rounded-md shadow-md transition-[margin-top,transform] duration-[0.4s,0.3s] group-[.show]:mt-16 group-[.modal-static]:scale-[1.05] dark:bg-darkmode-600 w-full';
  styleDivHeaderModal = 'flex items-center px-5 py-3 border-b border-slate-200/60 dark:border-darkmode-400';
  styleAX = 'absolute right-0 top-0 mr-3 mt-3';
  styleIconX = 'stroke-1.5 w-5 h-5 h-8 w-8 text-slate-400 h-8 w-8 text-slate-400';

  styleDataTable ='w-full text-left -mt-2 border-separate border-spacing-y-[10px]';
  styleThDataTable = 'font-medium px-5 py-3 dark:border-darkmode-300 whitespace-nowrap border-b-0';
  styleTdDataTable = 'px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600';
  styleTdButtonsDataTable = 'px-5 py-3 border-b dark:border-darkmode-300 box w-56 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 before:absolute before:inset-y-0 before:left-0 before:my-auto before:block before:h-8 before:w-px before:bg-slate-200 before:dark:bg-darkmode-400';
  styleListSmallButtonPrimary = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-primary border-primary text-white dark:border-primary mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonRoundedPrimary = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-full font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-primary border-primary text-white dark:border-primary mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonPending = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-pending border-pending text-white dark:border-pending mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonRoundedPending = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-full font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-pending border-pending text-white dark:border-pending mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonDark = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-dark border-dark text-white dark:border-dark mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonRoundedDark = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-full font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-dark border-dark text-white dark:border-dark mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonWarning = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-warning border-warning  dark:border-warning mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonRoundedWarning = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-full font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-warning border-warning  dark:border-warning mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonSuccess = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-success border-success  dark:border-success mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonRoundedSuccess = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-full font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-success border-success  dark:border-success mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonSecondary = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-secondary/70 border-secondary/70  dark:border-secondary/70 mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonRoundedSecondary = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-full font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-secondary/70 border-secondary/70  dark:border-secondary/70 mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonDanger = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-danger border-danger text-white dark:border-danger mb-2 mr-1 tooltip cursor-pointer';
  styleListSmallButtonRoundedDanger = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-1.5 px-2 rounded-full font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-xs bg-danger border-danger text-white dark:border-danger mb-2 mr-1 tooltip cursor-pointer';
  styleButtonSearch = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mb-2 mr-2 w-32';
  styleButtonPrimary = 'transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary mb-2 mr-2 w-32 shadow-md';
  styleButtonPending = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-pending border-pending text-white dark:border-pending  mb-2 mr-2 w-32';
  styleButtonDanger = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-danger border-danger text-white dark:border-danger mb-2 mr-2 w-32';
  styleInputText='disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10';  
  styleSelect = 'disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1';
  styleLabel = 'inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right';
  styleIconButtonText = 'stroke-1.5 w-5 h-5 mr-2 h-4 w-4 mr-2 h-4 w-4';
  styleIconButtonSmall = 'stroke-1.5 w-5 h-5';
  styleButtonTab = 'cursor-pointer block appearance-none px-5 py-2.5 border border-transparent text-slate-700 dark:text-slate-400 [&.active]:text-slate-800 [&.active]:dark:text-white shadow-[0px_3px_20px_#0000000b] rounded-md [&.active]:bg-primary [&.active]:text-white [&.active]:font-medium w-full py-2';
  styleTdTableSimple = 'px-5 py-3 border-b dark:border-darkmode-300 whitespace-nowrap';
  styleThTableSimple = 'font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap';
  styleSwitch = "transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&[type='radio']]:checked:bg-primary [&[type='radio']]:checked:border-primary [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary [&[type='checkbox']]:checked:border-primary [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed [&:disabled:checked]:dark:bg-darkmode-800/50 w-[38px] h-[24px] p-px rounded-full relative before:w-[20px] before:h-[20px] before:shadow-[1px_1px_3px_rgba(0,0,0,0.25)] before:transition-[margin-left] before:duration-200 before:ease-in-out before:absolute before:inset-y-0 before:my-auto before:rounded-full before:dark:bg-darkmode-600 checked:bg-primary checked:border-primary checked:bg-none before:checked:ml-[14px] before:checked:bg-white w-[38px] h-[24px] p-px rounded-full relative before:w-[20px] before:h-[20px] before:shadow-[1px_1px_3px_rgba(0,0,0,0.25)] before:transition-[margin-left] before:duration-200 before:ease-in-out before:absolute before:inset-y-0 before:my-auto before:rounded-full before:dark:bg-darkmode-600 checked:bg-primary checked:border-primary checked:bg-none before:checked:ml-[14px] before:checked:bg-white";
  styleSearchChat ='disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 border-transparent bg-slate-100 px-4 py-3 pr-10"';
  styleTextareaChat = 'disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm rounded-md placeholder:text-slate-400/90 focus:ring-primary focus:ring-opacity-20 focus:border-opacity-40 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 h-[46px] resize-none border-transparent px-5 py-3 shadow-sm border focus:border-transparent focus:ring-0 dark:bg-darkmode-600';
  styleUbicacionChat = 'transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-primary text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 mb-2 mr-1 inline-block w-64 mb-2 mr-1 inline-block w-64';

  constructor() {}

  
  StyleDivMainModal(): String {
    return this.styleDivMainModal;
  }  
  StyleDivHeaderModal(): String {
    return this.styleDivHeaderModal;
  }
  StyleAX(): String {
    return this.styleAX;
  }
  StyleIconX(): String {
    return this.styleIconX;
  }
  StyleDataTable(): String {
    return this.styleDataTable;
  }
  StyleThDataTable(): String {
    return this.styleThDataTable;
  }
  StyleTdDataTable(): String {
    return this.styleTdDataTable;
  }
  StyleTdButtonsDataTable(): String {
    return this.styleTdButtonsDataTable;
  }    
  StyleListSmallButtonPrimary(): String {
    return this.styleListSmallButtonPrimary;
  }
  StyleListSmallButtonRoundedPrimary(): String {
    return this.styleListSmallButtonRoundedPrimary;
  }
  StyleListSmallButtonPending(): String {
    return this.styleListSmallButtonPending;
  }
  StyleListSmallButtonRoundedPending(): String {
    return this.styleListSmallButtonRoundedPending;
  }
  StyleListSmallButtonDark(): String {
    return this.styleListSmallButtonDark;
  }
  StyleListSmallButtonRoundedDark(): String {
    return this.styleListSmallButtonRoundedDark;
  }
  StyleListSmallButtonWarning(): String {
    return this.styleListSmallButtonWarning;
  }
  StyleListSmallButtonRoundedWarning(): String {
    return this.styleListSmallButtonRoundedWarning;
  }
  StyleListSmallButtonSuccess(): String {
    return this.styleListSmallButtonSuccess;
  }
  StyleListSmallButtonRoundedSuccess(): String {
    return this.styleListSmallButtonRoundedSuccess;
  }
  StyleListSmallButtonSecondary(): String {
    return this.styleListSmallButtonSecondary;
  }
  StyleListSmallButtonRoundedSecondary(): String {
    return this.styleListSmallButtonRoundedSecondary;
  }
  StyleListSmallButtonDanger(): String {
    return this.styleListSmallButtonDanger;
  }
  StyleListSmallButtonRoundedDanger(): String {
    return this.styleListSmallButtonRoundedDanger;
  }
  StyleButtonSearch(): String {
    return this.styleButtonSearch;
  }
  StyleButtonPrimary(): String {
    return this.styleButtonPrimary;
  }
  StyleButtonPending(): String {
    return this.styleButtonPending;
  }
  StyleButtonDanger(): String {
    return this.styleButtonDanger;
  } 
  StyleInputText(): String {
    return this.styleInputText;
  }
  StyleLabel(): String {
    return this.styleLabel;
  }
  StyleIconButtonText(): String {
    return this.styleIconButtonText;
  }
  StyleIconButtonSmall(): String {
    return this.styleIconButtonSmall;
  }
  StyleSelect(): String {
    return this.styleSelect;
  }
  StyleButtonTab(): String {
    return this.styleButtonTab;
  }
  StyleTdTableSimple(): String {
    return this.styleTdTableSimple;
  }
  StyleThTableSimple(): String {
    return this.styleThTableSimple;
  }
  StyleSwitch(): String {
    return this.styleSwitch;
  }
  StyleSearchChat(): String {
    return this.styleSearchChat;
  }
  StyleTextareaChat(): String {
    return this.styleTextareaChat;
  }
  StyleUbicacionChat(): String {
    return this.styleUbicacionChat;
  }  
}
