import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { CargarScriptsService } from 'src/app/services/cargar-scripts.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  constructor(private cargarScriptsService: CargarScriptsService) {
  }

  ngOnInit(): void {
    console.log('tob bar');
    this.cargarScriptsService.CargarArchivoJS('vendors/popper.js', 'id-popper');
    this.cargarScriptsService.CargarArchivoJS('vendors/dropdown.js', 'id-dropdown');    
    this.cargarScriptsService.CargarArchivoJS('components/base/lucide.js', 'id-base-lucide');    
    this.cargarScriptsService.CargarArchivoJS('components/mobile-menu.js', 'id-mobile');
    this.cargarScriptsService.CargarArchivoJS('vendors/transition.js', 'id-transition');    
    this.cargarScriptsService.CargarArchivoJS('themes/rubick.js', 'id-rubick');
  }

  CerrarToolBar(){        
    let yourElem = <HTMLElement>document.querySelector('body');
    yourElem.click();
  }
}
